import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BiggerTooltip from '../tooltip/Tooltip';
import styles from './SectionWrapper.module.css';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface SectionWrapperProps {
    title?: string
    tooltip?: string
    children: React.ReactNode
}

const SectionWrapper = (props: SectionWrapperProps) => {

    const { title, tooltip, children } = props;

    const createTitle = () => {

        if (title && tooltip) {
            return (
                <div className={styles['section-title-container']}>
                    <h3 className={styles['section-title']}>{title}</h3>
                    <BiggerTooltip title={tooltip}>
                        <i><FontAwesomeIcon icon={faInfoCircle} /></i>
                    </BiggerTooltip>
                </div>
            );
        }

        if (title) {
            return (
                <h3 className={styles['section-title']}>{title}</h3>
            );
        }

        return null;
    }

    return (
        <div className={styles['section-wrapper']}>
            {createTitle()}
            {children}
        </div>
    );
}

export default SectionWrapper;