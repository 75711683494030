import styles from './SpaceAdaptive.module.css';

export const SpaceAdaptiveSmall = () => {
    return (
        <div className={styles['space-adaptive-small']}></div>
    );
}

export const SpaceAdaptiveMedium = () => {
    return (
        <div className={styles['space-adaptive-medium']}></div>
    );
}

export const SpaceAdaptiveLarge = () => {
    return (
        <div className={styles['space-adaptive-large']}></div>
    );
}