import styles from './Divider.module.css';

interface DividerProps {
    light?: boolean
}

export const Divider = ({ light }: DividerProps) => {
    return (
        <div className={[styles['divider'], light ? styles['divider--light'] : null].join(' ')}></div>
    );
}