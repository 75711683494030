export const formatDate = (date: Date, type: 'dt' | 'd' | 'y') => {
    var hours = String(date.getHours()),
        minutes = String(date.getMinutes()),
        seconds = String(date.getSeconds()),
        month = String((date.getMonth() + 1)),
        day = String(date.getDate()),
        year = date.getFullYear();

    if (hours.length < 2)
        hours = '0' + hours;
    if (minutes.length < 2)
        minutes = '0' + minutes;
    if (seconds.length < 2)
        seconds = '0' + seconds;
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    if (type === 'dt')
        return [year, month, day].join('-') + 'T' + [hours, minutes, seconds].join(':');
    else if (type === 'd')
        return [year, month, day].join('-');
    else if (type === 'y')
        return year;
    else
        return [year, month, day].join('-');
}