import { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { create } from 'xmlbuilder2';
import tooltips from '../../assets/tooltips.json';
import AIFMContentTypesJson from '../../assets/aifmContentTypes.json';
import aifmReportingCodesJson from '../../assets/aifmReportingCodes.json';
import reportPeriodsJson from '../../assets/reportingPeriods.json';
import marketTypesJson from '../../assets/marketTypes.json';
import subAssetsJson from '../../assets/subAssetsType.json';
import booleanChoiceJson from '../../assets/booleanChoice.json';
import baseCurrenciesJson from '../../assets/baseCurrencies.json';
import fxEURReferenceRateTypesJson from '../../assets/fxEURReferenceRateTypes.json';
import { selectStyles } from '../../styles/SelectStyles';
import { formatDate } from '../../utils/DateUtils';
import SectionWrapper from '../sectionWrapper/SectionWrapper';
import { Button } from '../button/Button';
import OptionWrapper from '../optionWrapper/OptionWrapper';
import ComplexOption from '../complexOption/ComplexOption';
import { PaneContent } from '../pageSecion/PageSection';
import OptionsGroup from '../optionsGroup/OptionsGroup';
import MultipleElementsWrapper from '../multipleElementsWrapper/MultipleElementsWrapper';

export default function AIFMCreator(props) {

    const [aifmName, setAIFMName] = useState('');
    const [selectedAIFMContentType, setSelectedAIFMContentType] = useState({ value: '2', label: '2 - for 3(3) (d) reporting contents for all AIFs managed' });
    const [aifmJurisdiction, setAIFMJurisdiction] = useState('PL');
    const [aifmNationalCode, setAIFMNationalCode] = useState('');
    const [selectedLastReportingFlag, setSelectedLastReportingFlag] = useState({ value: false, label: 'False' });
    const [selectedAIFMEEAFlag, setSelectedAIFMEEAFlag] = useState({ value: false, label: 'False' });
    const [selectedAIFMNoReportingFlag, setSelectedAIFMNoReportingFlag] = useState({ value: false, label: 'False' });
    const [selectedAIFMReportingCode, setSelectedAIFMReportingCode] = useState({ value: '1', label: '1 - Registered AIFM' });
    const [reportingPeriodStartDate, setReportingPeriodStartDate] = useState(new Date());
    const [reportingPeriodEndDate, setReportingPeriodEndDate] = useState(new Date());
    const [selectedReportPeriod, setSelectedReportPeriod] = useState({ value: 'Y1', label: 'Y1 - for the calendar year' });
    const [reportingPeriodYear, setReportingPeriodYear] = useState(new Date());
    const [aumAmountInEuro, setAUMAmountInEuro] = useState(undefined);
    const [selectedBaseCurrency, setSelectedBaseCurrency] = useState({ value: 'PLN', label: 'PLN' });
    const [aumAmountInBaseCurrency, setAUMAmountInBaseCurrency] = useState(undefined);
    const [selectedFXEURReferenceRateType, setSelectedFXEURReferenceRateType] = useState({ value: 'ECB', label: 'ECB' });
    const [fxEURRate, setFXEURRate] = useState(undefined);
    const [fxEUROtherReferenceRateDescription, setFXEUROtherReferenceRateDescription] = useState(undefined);
    const [aifmPrincipalMarkets, setAIFMPrincipalMarkets] = useState([
        {
            ranking: 1,
            marketType: { value: "NOT", label: "NOT" },
            marketCode: undefined,
            aggregatedValueAmount: undefined
        },
        {
            ranking: 2,
            marketType: { value: "NOT", label: "NOT" },
            marketCode: undefined,
            aggregatedValueAmount: undefined
        },
        {
            ranking: 3,
            marketType: { value: "NOT", label: "NOT" },
            marketCode: undefined,
            aggregatedValueAmount: undefined
        },
        {
            ranking: 4,
            marketType: { value: "NOT", label: "NOT" },
            marketCode: undefined,
            aggregatedValueAmount: undefined
        },
        {
            ranking: 5,
            marketType: { value: "NOT", label: "NOT" },
            marketCode: undefined,
            aggregatedValueAmount: undefined
        }
    ]);
    const [aifmPrincipalInstruments, setAIFMPrincipalInstruments] = useState([
        {
            ranking: 1,
            subAssetType: { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" },
            aggregatedValueAmount: undefined
        },
        {
            ranking: 2,
            subAssetType: { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" },
            aggregatedValueAmount: undefined
        },
        {
            ranking: 3,
            subAssetType: { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" },
            aggregatedValueAmount: undefined
        },
        {
            ranking: 4,
            subAssetType: { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" },
            aggregatedValueAmount: undefined
        },
        {
            ranking: 5,
            subAssetType: { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" },
            aggregatedValueAmount: undefined
        }
    ]);

    const marketTypeAIFMChange = index => (selectedObject) => {
        let array = aifmPrincipalMarkets;
        array[index].marketType = selectedObject === undefined ? {} : selectedObject;
        setAIFMPrincipalMarkets([...array]);
    };

    const marketCodeAIFMChange = index => (e) => {
        let array = aifmPrincipalMarkets;
        array[index].marketCode = e.target.value;
        setAIFMPrincipalMarkets([...array]);
    };

    const marketAmountChange = index => (e) => {
        let array = aifmPrincipalMarkets;
        array[index].aggregatedValueAmount = e.target.value;
        setAIFMPrincipalMarkets([...array]);
    };

    const subAssetTypeChange = index => (selectedObject) => {
        let array = aifmPrincipalInstruments;
        array[index].subAssetType = selectedObject === undefined ? {} : selectedObject;
        setAIFMPrincipalInstruments([...array]);
    };

    const subAssetAmountChange = index => (e) => {
        let array = aifmPrincipalInstruments;
        array[index].aggregatedValueAmount = e.target.value;
        setAIFMPrincipalInstruments([...array]);
    };

    const aifmPrincipalInstrumentsFormat = () => {
        let array = [];

        for (let i = 0; i < aifmPrincipalInstruments.length; i++) {
            let obj = {
                AIFMPrincipalInstrument: {
                    Ranking: aifmPrincipalInstruments[i].ranking,
                    SubAssetType: "NTA_NTA_NOTA"
                }
            };
            if (aifmPrincipalInstruments[i].aggregatedValueAmount !== undefined && aifmPrincipalInstruments[i].subAssetType.value !== "NTA_NTA_NOTA") {
                obj.AIFMPrincipalInstrument.SubAssetType = aifmPrincipalInstruments[i].subAssetType.value;
                obj.AIFMPrincipalInstrument.AggregatedValueAmount = aifmPrincipalInstruments[i].aggregatedValueAmount;
            }
            array.push(obj);
        }
        return array;
    }

    const aifmPrincipalMarketsFormat = () => {
        let array = [];

        for (let i = 0; i < aifmPrincipalMarkets.length; i++) {
            let obj = {
                AIFMFivePrincipalMarket: {
                    Ranking: aifmPrincipalMarkets[i].ranking,
                    MarketIdentification: {
                        MarketCodeType: "NOT"
                    }
                }
            };
            if (aifmPrincipalMarkets[i].aggregatedValueAmount !== undefined
                && aifmPrincipalMarkets[i].marketType.value !== "NOT"
                && aifmPrincipalMarkets[i].marketCode !== undefined) {
                obj.AIFMFivePrincipalMarket.MarketIdentification.MarketCodeType = aifmPrincipalMarkets[i].marketType.value;
                obj.AIFMFivePrincipalMarket.MarketIdentification.MarketCode = aifmPrincipalMarkets[i].marketCode;
                obj.AIFMFivePrincipalMarket.AggregatedValueAmount = aifmPrincipalMarkets[i].aggregatedValueAmount;
            }
            array.push(obj);
        }
        return array;
    }

    const getCurrencyBaseDescription = (type) => {
        let obj;
        if (type === 'AIFM') {
            obj = {
                "AIFMBaseCurrencyDescription": {
                    "BaseCurrency": selectedBaseCurrency.value,
                    "AUMAmountInBaseCurrency": aumAmountInBaseCurrency,
                    "FXEURReferenceRateType": selectedFXEURReferenceRateType.value,
                    "FXEURRate": fxEURRate,
                }
            }
        } else {
            obj = {
                "AIFBaseCurrencyDescription": {
                    "BaseCurrency": selectedBaseCurrency.value,
                    "AUMAmountInBaseCurrency": aumAmountInBaseCurrency,
                    "FXEURReferenceRateType": selectedFXEURReferenceRateType.value,
                    "FXEURRate": fxEURRate,
                }
            }
        }

        if (selectedFXEURReferenceRateType.value === 'OTH') {
            obj.FXEUROtherReferenceRateDescription = fxEUROtherReferenceRateDescription;
        }

        return obj;
    }

    function parseXML(obj) {
        const aifmRecordInfoObj = obj.AIFMReportingInfo.AIFMRecordInfo;
        setSelectedAIFMContentType(AIFMContentTypesJson.find((e) => e.value === aifmRecordInfoObj.AIFMContentType));
        setReportingPeriodStartDate(new Date(aifmRecordInfoObj.ReportingPeriodStartDate));
        setReportingPeriodEndDate(new Date(aifmRecordInfoObj.ReportingPeriodEndDate));
        setSelectedReportPeriod(reportPeriodsJson.find((e) => e.value === aifmRecordInfoObj.ReportingPeriodType));
        setReportingPeriodYear(new Date(aifmRecordInfoObj.ReportingPeriodYear));
        setSelectedAIFMReportingCode(AIFMContentTypesJson.find((e) => e.value === aifmRecordInfoObj.AIFMReportingCode));
        setSelectedLastReportingFlag(booleanChoiceJson.find((e) => e.label.toUpperCase() === aifmRecordInfoObj.LastReportingFlag.toUpperCase()));
        setAIFMJurisdiction(aifmRecordInfoObj.AIFMJurisdiction);
        setAIFMNationalCode(aifmRecordInfoObj.AIFMNationalCode);
        setAIFMName(aifmRecordInfoObj.AIFMName);
        setSelectedAIFMEEAFlag(booleanChoiceJson.find((e) => e.label.toUpperCase() === aifmRecordInfoObj.AIFMEEAFlag.toUpperCase()));
        setSelectedAIFMNoReportingFlag(booleanChoiceJson.find((e) => e.label.toUpperCase() === aifmRecordInfoObj.AIFMNoReportingFlag.toUpperCase()));

        setAUMAmountInEuro(aifmRecordInfoObj.AIFMCompleteDescription.AUMAmountInEuro);

        const aifmPrincipalMarkets = aifmRecordInfoObj.AIFMCompleteDescription.AIFMPrincipalMarkets.AIFMFivePrincipalMarket;
        let array = [];
        for (let i = 0; i < aifmPrincipalMarkets.length; i++) {
            let marketCode = marketTypesJson.find((e) => e.value === aifmPrincipalMarkets[i].MarketIdentification.MarketCodeType);
            let obj = {
                ranking: aifmPrincipalMarkets[i].Ranking,
                marketType: marketCode === undefined ? { value: "MIC", label: "MIC" } : marketCode,
                marketCode: aifmPrincipalMarkets[i].MarketIdentification.MarketCode,
                aggregatedValueAmount: aifmPrincipalMarkets[i].AggregatedValueAmount
            };
            array.push(obj);
        }
        setAIFMPrincipalMarkets(array);

        const aifmPrincipalInstruments = aifmRecordInfoObj.AIFMCompleteDescription.AIFMPrincipalInstruments.AIFMPrincipalInstrument;
        array = [];
        for (let i = 0; i < aifmPrincipalInstruments.length; i++) {
            let subAssetType;
            for (let j = 0; j < subAssetsJson.length; j++) {
                subAssetType = subAssetsJson[j].options.find((e) => e.value === aifmPrincipalInstruments[i].SubAssetType);
                if (subAssetType !== undefined)
                    break;
            }
            let obj = {
                ranking: aifmPrincipalInstruments[i].Ranking,
                subAssetType: subAssetType === undefined ? { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" } : subAssetType,
                aggregatedValueAmount: aifmPrincipalInstruments[i].AggregatedValueAmount
            };
            array.push(obj);
        }
        setAIFMPrincipalInstruments(array);

        const aifmBaseCurrencyDescription = aifmRecordInfoObj.AIFMCompleteDescription.AIFMBaseCurrencyDescription;
        setSelectedBaseCurrency(baseCurrenciesJson.find((e) => e.value === aifmBaseCurrencyDescription.BaseCurrency));
        setAUMAmountInBaseCurrency(aifmBaseCurrencyDescription.AUMAmountInBaseCurrency);
        setSelectedFXEURReferenceRateType(fxEURReferenceRateTypesJson.find((e) => e.value === aifmBaseCurrencyDescription.FXEURReferenceRateType));
        setFXEURRate(aifmBaseCurrencyDescription.FXEURRate);
        setFXEUROtherReferenceRateDescription(aifmBaseCurrencyDescription.FXEUROtherReferenceRateDescription);
    }

    function handleCreateXMLClick() {
        const xmlDoc = create({ encoding: "UTF-8" });
        const xsi = 'http://www.w3.org/2001/XMLSchema-instance';
        const nsDatman = 'AIFMD_DATMAN_V1.2.xsd';
        const aifmReportingInfo = {
            "AIFMReportingInfo": {
                '@CreationDateAndTime': formatDate(new Date(), 'dt'),
                '@Version': 1.2,
                '@ReportingMemberState': 'PL'
            }
        };

        const aifmRecordInfo = {
            "AIFMRecordInfo": {
                "FilingType": 'INIT',
                "AIFMContentType": selectedAIFMContentType.value,
                "ReportingPeriodStartDate": formatDate(reportingPeriodStartDate, 'd'),
                "ReportingPeriodEndDate": formatDate(reportingPeriodEndDate, 'd'),
                "ReportingPeriodType": selectedReportPeriod.value,
                "ReportingPeriodYear": formatDate(reportingPeriodYear, 'y'),
                "LastReportingFlag": selectedLastReportingFlag.value,
                "AIFMReportingCode": selectedAIFMReportingCode.value,
                "AIFMJurisdiction": aifmJurisdiction,
                "AIFMNationalCode": aifmNationalCode,
                "AIFMName": aifmName,
                "AIFMEEAFlag": selectedAIFMEEAFlag.value,
                "AIFMNoReportingFlag": selectedAIFMNoReportingFlag.value
            }
        };

        if (!selectedAIFMNoReportingFlag.value) {
            xmlDoc
                .ele(aifmReportingInfo).att(xsi, 'xsi:noNamespaceSchemaLocation', nsDatman)
                .ele(aifmRecordInfo)
                .ele("AIFMCompleteDescription")
                .ele("AIFMPrincipalMarkets")
                .ele(aifmPrincipalMarketsFormat()).up().up()
                .ele("AIFMPrincipalInstruments")
                .ele(aifmPrincipalInstrumentsFormat()).up().up()
                .ele("AUMAmountInEuro").txt(aumAmountInEuro).up()
                .ele(getCurrencyBaseDescription('AIFM')).up();
        } else {
            xmlDoc
                .ele(aifmReportingInfo).att(xsi, 'xsi:noNamespaceSchemaLocation', nsDatman)
                .ele(aifmRecordInfo);
        }

        const compl = xmlDoc.end({ prettyPrint: true });

        const element = document.createElement("a");
        const file = new Blob([compl], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = aifmNationalCode + "_" + aifmNationalCode + "_DATMAN_" + formatDate(reportingPeriodYear, 'y') + "_" + selectedReportPeriod.value + "_000.xml"
        document.body.appendChild(element);
        element.click();
    }

    useEffect(() => {
        if (props.obj !== null && props.obj !== undefined) {
            parseXML(props.obj);
        }
    }, [props.obj]);

    return (
        <PaneContent>
            <SectionWrapper
                title='Basic Info'>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'AIFM Name'}
                        tooltip={tooltips.AIFMName}
                    >
                        <input className="is-input" placeholder='AIFM Name' value={aifmName} onChange={(e) => setAIFMName(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AIFM National Code'}
                        tooltip={tooltips.AIFMNationalCode}
                    >
                        <input className="is-input" placeholder="AIFM National Code" value={aifmNationalCode} onChange={(e) => setAIFMNationalCode(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AIFM Jurisdiction'}
                        tooltip={tooltips.AIFMJurisdiction}
                    >
                        <input className="is-input" placeholder="AIFM Jurisdiction" value={aifmJurisdiction} onChange={(e) => setAIFMJurisdiction(e.target.value)} />
                    </OptionWrapper>
                </OptionsGroup>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'AIFM Reporting Code'}
                        tooltip={tooltips.AIFMReportingCode}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="AIFM Reporting Code" value={selectedAIFMReportingCode} options={aifmReportingCodesJson} onChange={(selectedObject) => setSelectedAIFMReportingCode(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AIFM Content Typ'}
                        tooltip={tooltips.AIFMContentType}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="AIFM Content Type" value={selectedAIFMContentType} options={AIFMContentTypesJson} onChange={(selectedObject) => setSelectedAIFMContentType(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Last Reporting Flag'}
                        tooltip={tooltips.LastReportingFlag}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="Last Reporting Flag" value={selectedLastReportingFlag} options={booleanChoiceJson} onChange={(selectedObject) => setSelectedLastReportingFlag(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AIFM EEA Flag'}
                        tooltip={tooltips.AIFMEEAFlag}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="AIFM EEA Flag" value={selectedAIFMEEAFlag} options={booleanChoiceJson} onChange={(selectedObject) => setSelectedAIFMEEAFlag(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AIFM No Reporting Flag'}
                        tooltip={tooltips.AIFNoReportingFlag}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="AIFM No Reporting Flag" value={selectedAIFMNoReportingFlag} options={booleanChoiceJson} onChange={(selectedObject) => setSelectedAIFMNoReportingFlag(selectedObject)} />
                    </OptionWrapper>
                </OptionsGroup>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'Reporting Period Start Date'}
                        tooltip={tooltips.ReportingPeriodStartDate}
                    >
                        <DatePicker className="is-input" dateFormat="yyyy-MM-dd" selected={reportingPeriodStartDate} onChange={(date) => setReportingPeriodStartDate(date)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Reporting Period End Date'}
                        tooltip={tooltips.ReportingPeriodEndDate}
                    >
                        <DatePicker className="is-input" dateFormat="yyyy-MM-dd" selected={reportingPeriodEndDate} onChange={(date) => setReportingPeriodEndDate(date)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Reporting Period Type'}
                        tooltip={tooltips.ReportingPeriodType}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="Reporting Period Type" value={selectedReportPeriod} options={reportPeriodsJson} onChange={(selectedObject) => setSelectedReportPeriod(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Reporting Period Year'}
                        tooltip={tooltips.ReportingPeriodYear}
                    >
                        <DatePicker className="is-input" showYearPicker dateFormat="yyyy" selected={reportingPeriodYear} onChange={(date) => setReportingPeriodYear(date)} />
                    </OptionWrapper>
                </OptionsGroup>
            </SectionWrapper>
            <SectionWrapper
                title='AIFM Principal Markets'
                tooltip={tooltips.top5PrinicipalMarkets}>
                <MultipleElementsWrapper>
                    {
                        aifmPrincipalMarkets.map((market, index) => {
                            return (
                                <ComplexOption
                                    key={index}
                                    title={'Ranking ' + market.ranking}
                                    tooltip={tooltips.rankingPrincipalMarketAIFM}
                                    elementIndex={index}>
                                    <OptionWrapper
                                        fieldName={'Market Code Type'}
                                        tooltip={tooltips.marketCodeType}
                                    >
                                        <Select styles={selectStyles} className="is-custom-select" placeholder='Market Type Code' value={market.marketType} options={marketTypesJson} onChange={marketTypeAIFMChange(index)} />
                                    </OptionWrapper>
                                    {market.marketType.value === 'MIC' && <OptionWrapper
                                        fieldName={'Market Code'}
                                        tooltip={tooltips.marketCode}
                                    >
                                        <input className="is-input" placeholder="Market Code" value={market.marketCode} onChange={marketCodeAIFMChange(index)} />
                                    </OptionWrapper>}
                                    {market.marketType.value !== 'NOT' && <OptionWrapper
                                        fieldName={'Aggregated Value Amount'}
                                        tooltip={tooltips.aggregatedValueAmountMarketAIFM}
                                    >
                                        <input className="is-input" placeholder="Aggregated Value Amount" value={market.aggregatedValueAmount} onChange={marketAmountChange(index)} />
                                    </OptionWrapper>}
                                </ComplexOption>
                            );
                        })
                    }
                </MultipleElementsWrapper>
            </SectionWrapper>
            <SectionWrapper
                title='AIFM Principal Instruments'
                tooltip={tooltips.AIFMPrincipalInstruments}>
                <MultipleElementsWrapper>
                    {
                        aifmPrincipalInstruments.map((instrument, index) => {
                            return (
                                <ComplexOption
                                    key={index}
                                    title={'Ranking ' + instrument.ranking}
                                    tooltip={tooltips.rankingPrincipalInstrumentsAIFM}
                                    elementIndex={index}>
                                    <OptionWrapper
                                        fieldName={'Sub-Asset Type'}
                                        tooltip={tooltips.subAssetTypePrincipalInstrumentsAIFM}
                                    >
                                        <Select styles={selectStyles} className="is-custom-select" placeholder='--Wybierz wartość--' value={instrument.subAssetType} options={subAssetsJson} onChange={subAssetTypeChange(index)} />
                                    </OptionWrapper>
                                    {instrument.subAssetType.value !== 'NTA_NTA_NOTA' && <OptionWrapper
                                        fieldName={'Aggregated Value Amount'}
                                        tooltip={tooltips.aggregatedValueAmountPrincipalInstrument}
                                    >
                                        <input className="is-input" placeholder="Aggregated Value Amount" value={instrument.aggregatedValueAmount} onChange={subAssetAmountChange(index)} />
                                    </OptionWrapper>}
                                </ComplexOption>
                            );
                        })
                    }
                </MultipleElementsWrapper>
                <OptionWrapper
                    fieldName={'AUM Amount In Euro'}
                    tooltip={tooltips.AUMAmountInEuro}
                >
                    <input className="is-input" placeholder='AUM Amount In Euro' value={aumAmountInEuro} onChange={(e) => setAUMAmountInEuro(e.target.value)} />
                </OptionWrapper>
            </SectionWrapper>
            <SectionWrapper>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'Base Currency'}
                        tooltip={tooltips.AIFMBaseCurrency}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="Base Currency" value={selectedBaseCurrency} options={baseCurrenciesJson} onChange={(selectedObject) => setSelectedBaseCurrency(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AUM Amount In Base Currency'}
                        tooltip={tooltips.AIFMAUMAmountInBaseCurrency}
                    >
                        <input className="is-input" placeholder='AUM Amount In Base Currency' value={aumAmountInBaseCurrency} onChange={(e) => { setAUMAmountInBaseCurrency(e.target.value); }} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'FX EUR Reference Rate Type'}
                        tooltip={tooltips.AIFFXEURReferenceRateType}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="FX EUR Reference Rate Type" value={selectedFXEURReferenceRateType} options={fxEURReferenceRateTypesJson} onChange={(selectedObject) => setSelectedFXEURReferenceRateType(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'FX EUR Rate'}
                        tooltip={tooltips.AIFFXEURRate}
                    >
                        <input className="is-input" placeholder='FX EUR Rate' value={fxEURRate} onChange={(e) => { setFXEURRate(e.target.value); }} />
                    </OptionWrapper>
                    {selectedFXEURReferenceRateType.value === "OTH" && <OptionWrapper
                        fieldName={'FX EUR Other Reference Rate Description'}
                        tooltip={tooltips.FXEUROtherReferenceRateDescription}
                    >
                        <input className="is-input" placeholder='FX EUR Other Reference Rate Description' value={fxEUROtherReferenceRateDescription} onChange={(e) => { setFXEUROtherReferenceRateDescription(e.target.value); }} />
                    </OptionWrapper>}
                </OptionsGroup>
            </SectionWrapper>
            <SectionWrapper>
                <Button onClick={() => handleCreateXMLClick()}>Generuj XML</Button>
            </SectionWrapper>
        </PaneContent>
    )
}