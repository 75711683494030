import styles from './PageContainer.module.css';

interface PageLoaderContainerProps {
    children?: React.ReactNode
}

export const PageLoaderContainer = ({ children }: PageLoaderContainerProps) => {
    return (
        <main className={[styles['page'], styles['page-loader']].join(' ')}>
            {children}
        </main>
    );
}

interface PageContainerProps {
    backgroundColor?: string,
    backgroundImage?: string,
    fullWidth?: boolean,
    children: React.ReactNode
}

export const PageContainer = ({ children, fullWidth = false, backgroundColor, backgroundImage }: PageContainerProps) => {

    const setInnerWrapper = () => {
        if (fullWidth) {
            return (children)
        }

        return (
            <div className={styles['pane-content']}>
                {children}
            </div>
        )
    }

    const setClasses = () => {
        if (backgroundImage) {
            return [styles['pane'], styles['pane--adaptiveHg'], styles['pane--flush']]
        }
        return [styles['pane'], styles['pane--full'], styles['pane--adaptiveHg']]
    }

    const setBackgroundColor = () => {
        if (backgroundColor) {
            return (
                { backgroundColor: backgroundColor }
            )
        }
        return undefined;
    }

    const setBackgroundImage = () => {
        if (backgroundImage) {
            return (
                <div className={styles['pane-backgroundContainer']}>
                    <div className={styles['pane-background']} style={{ backgroundPosition: 'top', backgroundImage: 'url(' + backgroundImage + ')' }}></div>
                </div>
            )
        }
        return null;
    }

    return (
        <main className={styles['page']}>
            <section className={setClasses().join(' ')} style={setBackgroundColor()}>
                {setBackgroundImage()}
                {setInnerWrapper()}
            </section>
        </main>
    );
}