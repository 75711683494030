import { StylesConfig } from "react-select";

export const selectStyles: StylesConfig = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#1e90ff' : state.isFocused ? '#08253c' : 'black',
        color: state.isSelected ? 'white' : state.isFocused ? 'white' : '#aaa',
        borderBottom: '1px solid #08253c',
        padding: 20
    }),
    group: (provided) => ({
        ...provided,
        backgroundColor: '#08253c',
        color: '#aaa'
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#08253c',
        color: '#aaa'
    }),
    control: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : '#aaa',
        backgroundColor: '#08253c',
        borderColor: 'black'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'white'
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'white'
    }),
    input: (provided) => ({
        ...provided,
        color: 'white'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        backgroundColor: 'black',
        color: 'white'
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : 'red'
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : 'red',
        backgroundColor: 'black'
    }),
};