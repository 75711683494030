import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ComplexOption.module.css';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MouseEvent } from 'react';
import BiggerTooltip from '../tooltip/Tooltip';

interface ComplexOptionProps {
    title: string
    tooltip?: string
    elementIndex: number
    onDelete?: (event: MouseEvent<HTMLDivElement>, index: number) => {}
    children: React.ReactNode
}

const ComplexOption = (props: ComplexOptionProps) => {

    const { title, tooltip, elementIndex, onDelete, children } = props;

    const createHeader = () => {

        const createTitle = () => {
            if (tooltip) {
                return (
                    <BiggerTooltip title={tooltip}>
                        <span>{title}</span>
                    </BiggerTooltip>
                );
            } else {
                return (
                    <span>{title}</span>
                )
            }
        }

        const createDeleteButton = () => {
            if (onDelete) {
                return (
                    <div className={styles['filter-delete']} onClick={(e) => onDelete(e, elementIndex)}>
                        <i><FontAwesomeIcon icon={faTimes} /></i>
                    </div>
                )
            }

            return null;
        }

        return (
            <div className={styles['filter-header']}>
                {createTitle()}
                {createDeleteButton()}
            </div>
        )
    }

    return (
        <div className={styles['filter-wrapper']}>
            {createHeader()}
            <div className={styles['filter-content']}>
                {children}
            </div>
        </div>
    );
}

export default ComplexOption;