import { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { create } from 'xmlbuilder2';
import tooltips from '../../assets/tooltips.json';
import reportPeriodsJson from '../../assets/reportingPeriods.json';
import cancellationRecordFlags from '../../assets/cancellationRecordFlags.json';
import { selectStyles } from '../../styles/SelectStyles';
import { formatDate } from '../../utils/DateUtils';
import OptionWrapper from '../optionWrapper/OptionWrapper';
import { Button } from '../button/Button';
import SectionWrapper from '../sectionWrapper/SectionWrapper';
import { PaneContent } from '../pageSecion/PageSection';
import OptionsGroup from '../optionsGroup/OptionsGroup';

export default function AIFCancel(props) {

    const [aifNationalCode, setAIFNationalCode] = useState('');
    const [aifmNationalCode, setAIFMNationalCode] = useState('');
    const [selectedReportPeriod, setSelectedReportPeriod] = useState({ value: 'Y1', label: 'Y1 - for the calendar year' });
    const [reportingPeriodYear, setReportingPeriodYear] = useState(new Date());
    const [selectedCancelledRecordFlag, setSelectedCancelledRecordFlag] = useState({ value: 'C', label: 'C - cancelled by the AIFM' });

    const parseXML = (obj) => {
        const cancellationAIFRecordInfoObj = obj.AIFReportingInfo.CancellationAIFRecordInfo;
        setAIFNationalCode(cancellationAIFRecordInfoObj.CancelledAIFNationalCode);
        setAIFMNationalCode(cancellationAIFRecordInfoObj.CancelledAIFMNationalCode);
        setSelectedReportPeriod(reportPeriodsJson.find((e) => e.value === cancellationAIFRecordInfoObj.CancelledReportingPeriodType));
        setReportingPeriodYear(new Date(cancellationAIFRecordInfoObj.CancelledReportingPeriodYear));
        setSelectedCancelledRecordFlag(cancellationRecordFlags.find((e) => e.value.toUpperCase() === cancellationAIFRecordInfoObj.CancelledRecordFlag.toUpperCase()));
    }

    const handleCreateXMLClick = () => {
        const xmlDoc = create({ encoding: "UTF-8" });
        const xsi = 'http://www.w3.org/2001/XMLSchema-instance';
        const nsDataif = 'AIFMD_DATAIF_V1.2.xsd';

        const aifReportingInfo = {
            "AIFReportingInfo": {
                '@CreationDateAndTime': formatDate(new Date(), 'dt'),
                '@Version': 1.2,
                '@ReportingMemberState': 'PL'
            }
        };

        const cancellationAIFRecordInfo = {
            "CancellationAIFRecordInfo": {
                "CancelledAIFNationalCode": aifNationalCode,
                "CancelledAIFMNationalCode": aifmNationalCode,
                "CancelledReportingPeriodType": selectedReportPeriod.value,
                "CancelledReportingPeriodYear": formatDate(reportingPeriodYear, 'y'),
                "CancelledRecordFlag": selectedCancelledRecordFlag.value
            }
        };

        xmlDoc
            .ele(aifReportingInfo).att(xsi, 'xsi:noNamespaceSchemaLocation', nsDataif)
            .ele(cancellationAIFRecordInfo);

        const compl = xmlDoc.end({ prettyPrint: true });

        const element = document.createElement("a");
        const file = new Blob([compl], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = aifmNationalCode + "_" + aifNationalCode + "_DATAIF_" + formatDate(reportingPeriodYear, 'y') + "_" + selectedReportPeriod.value + "_000.xml";
        document.body.appendChild(element);
        element.click();
    }

    useEffect(() => {
        if (props.obj !== null && props.obj !== undefined) {
            parseXML(props.obj);
        }
    }, [props.obj]);

    return (
        <PaneContent>
            <SectionWrapper>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'AIF National Code'}
                        tooltip={tooltips.cancelledAIFNationalCode}
                    >
                        <input className="is-input" placeholder="AIF National Code" value={aifNationalCode} onChange={(e) => setAIFNationalCode(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AIFM National Code'}
                        tooltip={tooltips.cancelledAIFMNationalCode}
                    >
                        <input className="is-input" placeholder="AIFM National Code" value={aifmNationalCode} onChange={(e) => setAIFMNationalCode(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Reporting Period Type'}
                        tooltip={tooltips.cancelledReportingPeriodType}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="Reporting Period Type" value={selectedReportPeriod} options={reportPeriodsJson} onChange={(selectedObject) => setSelectedReportPeriod(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Reporting Period Year'}
                        tooltip={tooltips.cancelledReportingPeriodYear}
                    >
                        <DatePicker className="is-input" showYearPicker dateFormat="yyyy" selected={reportingPeriodYear} onChange={(date) => setReportingPeriodYear(date)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Cancelled Record Flag'}
                        tooltip={tooltips.cancelledRecordFlag}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="Cancelled Record Flag" value={selectedCancelledRecordFlag} options={cancellationRecordFlags} onChange={(selectedObject) => setSelectedCancelledRecordFlag(selectedObject)} />
                    </OptionWrapper>
                </OptionsGroup>
                <OptionsGroup>
                    <Button onClick={() => handleCreateXMLClick()}>Generuj XML</Button>
                </OptionsGroup>
            </SectionWrapper>
        </PaneContent>
    )
}