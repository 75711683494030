import { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { create, fragment } from 'xmlbuilder2';
import tooltips from '../../assets/tooltips.json';
import AIFMasterFeederStatusTypesJson from '../../assets/aifMasterFeederStatusTypes.json';
import AIFContentTypesJson from '../../assets/aifContentTypes.json';
import aifReportingCodesJson from '../../assets/aifReportingCodes.json';
import reportPeriodsJson from '../../assets/reportingPeriods.json';
import marketTypesJson from '../../assets/marketTypes.json';
import subAssetsJson from '../../assets/subAssetsType.json';
import booleanChoiceJson from '../../assets/booleanChoice.json';
import baseCurrenciesJson from '../../assets/baseCurrencies.json';
import fxEURReferenceRateTypesJson from '../../assets/fxEURReferenceRateTypes.json';
import predominantAIFTypesJson from '../../assets/predominantAIFTypes.json'
import strategiesListJson from '../../assets/strategiesList.json';
import instrumentCodeTypesJson from '../../assets/instrumentCodeTypes.json';
import countriesISOCodesJson from '../../assets/country-iso-codes.json';
import positionTypesJson from '../../assets/positionTypes.json';
import positionSizesJson from '../../assets/positionSizes.json';
import assetTypesJson from '../../assets/assetTypes.json';
import assetMacroTypesJson from '../../assets/assetMacroTypes.json';
import filingTypesJson from '../../assets/filingTypes.json';
import marketRiskMeasuresToSelect from '../../assets/marketRiskMeasures.json';
import BiggerTooltip from '../tooltip/Tooltip';
import { selectStyles } from '../../styles/SelectStyles';
import { formatDate } from '../../utils/DateUtils';
import SectionWrapper from '../sectionWrapper/SectionWrapper';
import OptionWrapper from '../optionWrapper/OptionWrapper';
import { Button } from '../button/Button';
import ComplexOption from '../complexOption/ComplexOption';
import { PaneContent } from '../pageSecion/PageSection';
import MultipleElementsWrapper from '../multipleElementsWrapper/MultipleElementsWrapper';
import OptionsGroup from '../optionsGroup/OptionsGroup';

export default function AIFCreator(props) {

    const [filingType, setFilingType] = useState({value: 'INIT', label: 'INIT'});
    const [aifName, setAIFName] = useState('');
    const [aifNationalCode, setAIFNationalCode] = useState('');
    const [selectedAIFContentType, setSelectedAIFContentType] = useState({ value: '3', label: '3 - for 3(3) (d) reporting obligation' });
    const [selectedAIFMasterFeederStatus, setSelectedAIFMasterFeederStatus] = useState({ value: 'NONE', label: 'NONE - for AIF that is neither feeder nor master' });
    const [aifDomicile, setAIFDomicile] = useState('PL');
    const [inceptionDate, setInceptionDate] = useState(new Date());
    const [aifmNationalCode, setAIFMNationalCode] = useState('');
    const [selectedLastReportingFlag, setSelectedLastReportingFlag] = useState({ value: false, label: 'False' });
    const [selectedAIFEEAFlag, setSelectedAIFEEAFlag] = useState({ value: false, label: 'False' });
    const [selectedAIFNoReportingFlag, setSelectedAIFNoReportingFlag] = useState({ value: false, label: 'False' });
    const [selectedShareClassFlag, setSelectedShareClassFlag] = useState({ value: false, label: 'False' });
    const [selectedAIFReportingCode, setSelectedAIFReportingCode] = useState({ value: '1', label: '1 - AIF (of an Registered AIFM)' });
    const [reportingPeriodStartDate, setReportingPeriodStartDate] = useState(new Date());
    const [reportingPeriodEndDate, setReportingPeriodEndDate] = useState(new Date());
    const [selectedReportPeriod, setSelectedReportPeriod] = useState({ value: 'Y1', label: 'Y1 - for the calendar year' });
    const [reportingPeriodYear, setReportingPeriodYear] = useState(new Date());
    const [selectedBaseCurrency, setSelectedBaseCurrency] = useState({ value: 'PLN', label: 'PLN' });
    const [aumAmountInBaseCurrency, setAUMAmountInBaseCurrency] = useState(undefined);
    const [selectedFXEURReferenceRateType, setSelectedFXEURReferenceRateType] = useState({ value: 'ECB', label: 'ECB' });
    const [fxEURRate, setFXEURRate] = useState(undefined);
    const [fxEUROtherReferenceRateDescription, setFXEUROtherReferenceRateDescription] = useState(undefined);
    const [aifNetAssetValue, setAIFNetAssetValue] = useState(undefined);
    const [firstFundingSourceCountry, setFirstFundingSourceCountry] = useState(undefined);
    const [secondFundingSourceCountry, setSecondFundingSourceCountry] = useState(undefined);
    const [thirdFundingSourceCountry, setThirdFundingSourceCountry] = useState(undefined);
    const [selectedPredominantAIFType, setSelectedPredominantAIFType] = useState({ value: "PEQF", label: "Private equity strategies", type: "PrivateEquityFund" });
    const [hftTransactionNumber, setHFTTransactionNumber] = useState(undefined);
    const [hftBuySellMarketValue, setHFTBuySellMarketValue] = useState(undefined);
    const [strategyTypesToSelect, setStrategyTypesToSelect] = useState([]);
    const [africaNAVRate, setAfricaNAVRate] = useState(0);
    const [asiaPacificNAVRate, setAsiaPacificNAVRate] = useState(0);
    const [europeNAVRate, setEuropeNAVRate] = useState(0);
    const [eEANAVRate, setEEANAVRate] = useState(0);
    const [middleEastNAVRate, setMiddleEastNAVRate] = useState(0);
    const [northAmericaNAVRate, setNorthAmericaNAVRate] = useState(0);
    const [southAmericaNAVRate, setSouthAmericaNAVRate] = useState(0);
    const [supraNationalNAVRate, setSupraNationalNAVRate] = useState(0);
    const [africaAUMRate, setAfricaAUMRate] = useState(0);
    const [asiaPacificAUMRate, setAsiaPacificAUMRate] = useState(0);
    const [europeAUMRate, setEuropeAUMRate] = useState(0);
    const [eEAAUMRate, setEEAAUMRate] = useState(0);
    const [middleEastAUMRate, setMiddleEastAUMRate] = useState(0);
    const [northAmericaAUMRate, setNorthAmericaAUMRate] = useState(0);
    const [southAmericaAUMRate, setSouthAmericaAUMRate] = useState(0);
    const [supraNationalAUMRate, setSupraNationalAUMRate] = useState(0);
    const [selectedTypicalPositionSize, setSelectedTypicalPositionSize] = useState({ value: "V_SMALL", label: "Very small (< 5 mln EUR)" });
    const [mainBeneficialOwnersRate, setMainBeneficialOwnersRate] = useState(0);
    const [professionalInvestorConcentrationRate, setProfessionalInvestorConcentrationRate] = useState(0);
    const [retailInvestorConcentrationRate, setRetailInvestorConcentrationRate] = useState(0);
    const [shareClassIdentifiers, setShareClassIdentifiers] = useState([]);
    const [aifPrincipalMarkets, setAIFPrincipalMarkets] = useState([
        {
            ranking: 1,
            marketType: { value: "NOT", label: "NOT" },
            marketCode: undefined,
            aggregatedValueAmount: undefined
        },
        {
            ranking: 2,
            marketType: { value: "NOT", label: "NOT" },
            marketCode: undefined,
            aggregatedValueAmount: undefined
        },
        {
            ranking: 3,
            marketType: { value: "NOT", label: "NOT" },
            marketCode: undefined,
            aggregatedValueAmount: undefined
        }
    ]);

    const [investmentStrategies, setInvestmentStrategies] = useState([]);
    const [mainInstrumentsTraded, setMainInstrumentsTraded] = useState([
        {
            ranking: 1,
            subAssetType: { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" },
            positionValue: undefined
        },
        {
            ranking: 2,
            subAssetType: { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" },
            positionValue: undefined
        },
        {
            ranking: 3,
            subAssetType: { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" },
            positionValue: undefined
        },
        {
            ranking: 4,
            subAssetType: { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" },
            positionValue: undefined
        },
        {
            ranking: 5,
            subAssetType: { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" },
            positionValue: undefined
        }
    ]);
    const [portfolioConcentrations, setPortfolioConcentrations] = useState([
        {
            ranking: 1,
            assetType: { value: "NTA_NTA", label: "NTA_NTA" },
            positionType: undefined,
            marketType: { value: '', label: null },
            marketCode: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 2,
            assetType: { value: "NTA_NTA", label: "NTA_NTA" },
            positionType: undefined,
            marketType: { value: '', label: null },
            marketCode: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 3,
            assetType: { value: "NTA_NTA", label: "NTA_NTA" },
            positionType: undefined,
            marketType: { value: '', label: null },
            marketCode: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 4,
            assetType: { value: "NTA_NTA", label: "NTA_NTA" },
            positionType: undefined,
            marketType: { value: '', label: null },
            marketCode: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 5,
            assetType: { value: "NTA_NTA", label: "NTA_NTA" },
            positionType: undefined,
            marketType: { value: '', label: null },
            marketCode: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        }
    ]);
    const [principalExposures, setPrincipalExposures] = useState([
        {
            ranking: 1,
            assetMacroType: { value: 'NTA', label: 'NTA' },
            subAssetType: undefined,
            positionType: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 2,
            assetMacroType: { value: 'NTA', label: 'NTA' },
            subAssetType: undefined,
            positionType: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 3,
            assetMacroType: { value: 'NTA', label: 'NTA' },
            subAssetType: undefined,
            positionType: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 4,
            assetMacroType: { value: 'NTA', label: 'NTA' },
            subAssetType: undefined,
            positionType: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 5,
            assetMacroType: { value: 'NTA', label: 'NTA' },
            subAssetType: undefined,
            positionType: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 6,
            assetMacroType: { value: 'NTA', label: 'NTA' },
            subAssetType: undefined,
            positionType: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 7,
            assetMacroType: { value: 'NTA', label: 'NTA' },
            subAssetType: undefined,
            positionType: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 8,
            assetMacroType: { value: 'NTA', label: 'NTA' },
            subAssetType: undefined,
            positionType: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 9,
            assetMacroType: { value: 'NTA', label: 'NTA' },
            subAssetType: undefined,
            positionType: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        },
        {
            ranking: 10,
            assetMacroType: { value: 'NTA', label: 'NTA' },
            subAssetType: undefined,
            positionType: undefined,
            aggregatedValueAmount: undefined,
            aggregatedValueRate: undefined,
            counterpartyEntityName: undefined,
            counterpartyEntityBIC: undefined,
            counterpartyEntityLEI: undefined
        }
    ]);

    const [annualInvestmentReturnRate, setAnnualInvestmentReturnRate] = useState(0.00);
    const [marketRiskMeasures, setMarketRiskMeasures] = useState([]);
    const [stressTestsResultArticle15, setStressTestsResultArticle15] = useState("");
    const [stressTestsResultArticle16, setStressTestsResultArticle16] = useState("");

    const marketTypeAIFChange = index => (selectedObject) => {
        let array = aifPrincipalMarkets;
        array[index].marketType = selectedObject === undefined ? {} : selectedObject;
        setAIFPrincipalMarkets([...array]);
    };

    const marketCodeAIFChange = index => (e) => {
        let array = aifPrincipalMarkets;
        array[index].marketCode = e.target.value;
        setAIFPrincipalMarkets([...array]);
    };

    const marketAmountAIFChange = index => (e) => {
        let array = aifPrincipalMarkets;
        array[index].aggregatedValueAmount = e.target.value;
        setAIFPrincipalMarkets([...array]);
    };

    const strategyTypeChange = index => (selectedObject) => {
        let array = investmentStrategies;
        array[index].strategyType = selectedObject === undefined ? {} : selectedObject;
        setInvestmentStrategies([...array]);
    };

    const strategyIsPrimaryChange = index => (selectedObject) => {
        let array = investmentStrategies;
        array[index].isPrimary = selectedObject === undefined ? {} : selectedObject;
        setInvestmentStrategies([...array]);
    };

    const strategyNavRateChange = index => (e) => {
        let array = investmentStrategies;
        array[index].navRate = e.target.value;
        setInvestmentStrategies([...array]);
    };

    function handleAddStrategy() {
        let array = investmentStrategies;
        array.push({
            strategyType: undefined,
            isPrimary: undefined,
            navRate: undefined
        });
        setInvestmentStrategies([...array]);
    }

    function handleDeleteStrategy(index) {
        let array = investmentStrategies;
        array.splice(index, 1);
        setInvestmentStrategies([...array]);
    }

    function handleAddShareClassIdentifier() {
        let array = shareClassIdentifiers;
        array.push({
            shareClassNationalCode: undefined,
            shareClassIdentifierISIN: undefined,
            shareClassIdentifierCUSIP: undefined,
            shareClassIdentifierSEDOL: undefined,
            shareClassIdentifierTicker: undefined,
            shareClassIdentifierRIC: undefined,
            shareClassName: undefined
        });
        setShareClassIdentifiers([...array]);
    }

    const handleDeleteShareClassIdentifier = (index) => {
        let array = shareClassIdentifiers;
        array.splice(index, 1);
        setShareClassIdentifiers([...array]);
    }

    const instrumentSubAssetTypeChange = index => (selectedObject) => {
        let array = mainInstrumentsTraded;
        array[index].subAssetType = selectedObject;
        setMainInstrumentsTraded([...array]);
    };

    const instrumentPositionValueChange = index => (e) => {
        let array = mainInstrumentsTraded;
        array[index].positionValue = e.target.value;
        setMainInstrumentsTraded([...array]);
    };

    const portfolioConcentrationAssetTypeChange = index => (selectedObject) => {
        let array = portfolioConcentrations;
        array[index].assetType = selectedObject;
        setPortfolioConcentrations([...array]);
    };

    const portfolioConcentrationPositionTypeChange = index => (selectedObject) => {
        let array = portfolioConcentrations;
        array[index].positionType = selectedObject === undefined ? {} : selectedObject;
        setPortfolioConcentrations([...array]);
    };

    const portfolioConcentrationValueAmountChange = index => (e) => {
        let array = portfolioConcentrations;
        array[index].aggregatedValueAmount = e.target.value;
        setPortfolioConcentrations([...array]);
    };

    const portfolioConcentrationValueRateChange = index => (e) => {
        let array = portfolioConcentrations;
        array[index].aggregatedValueRate = e.target.value;
        setPortfolioConcentrations([...array]);
    };

    const portfolioConcentrationMarketTypeChange = index => (selectedObject) => {
        let array = portfolioConcentrations;
        array[index].marketType = selectedObject === undefined ? {} : selectedObject;
        setPortfolioConcentrations([...array]);
    };

    const portfolioConcentrationMarketCodeChange = index => (e) => {
        let array = portfolioConcentrations;
        array[index].marketCode = e.target.value;
        setPortfolioConcentrations([...array]);
    };

    const portfolioConcentrationConterpartyNameChange = index => (e) => {
        let array = portfolioConcentrations;
        array[index].counterpartyEntityName = e.target.value;
        setPortfolioConcentrations([...array]);
    };

    const portfolioConcentrationConterpartyBICChange = index => (e) => {
        let array = portfolioConcentrations;
        array[index].counterpartyEntityBIC = e.target.value;
        setPortfolioConcentrations([...array]);
    };

    const portfolioConcentrationConterpartyLEIChange = index => (e) => {
        let array = portfolioConcentrations;
        array[index].counterpartyEntityLEI = e.target.value;
        setPortfolioConcentrations([...array]);
    };

    const exposureAssetMacroTypeChange = index => (selectedObject) => {
        let array = principalExposures;
        array[index].assetMacroType = selectedObject === undefined ? {} : selectedObject;
        setPrincipalExposures([...array]);
    };

    const exposureSubAssetTypeChange = index => (selectedObject) => {
        let array = principalExposures;
        array[index].subAssetType = selectedObject === undefined ? {} : selectedObject;
        setPrincipalExposures([...array]);
    };

    const exposurePositionTypeChange = index => (selectedObject) => {
        let array = principalExposures;
        array[index].positionType = selectedObject === undefined ? {} : selectedObject;
        setPrincipalExposures([...array]);
    };

    const exposureValueAmountChange = index => (e) => {
        let array = principalExposures;
        array[index].aggregatedValueAmount = e.target.value;
        setPrincipalExposures([...array]);
    };

    const exposureValueRateChange = index => (e) => {
        let array = principalExposures;
        array[index].aggregatedValueRate = e.target.value;
        setPrincipalExposures([...array]);
    };

    const exposureConterpartyNameChange = index => (e) => {
        let array = principalExposures;
        array[index].counterpartyEntityName = e.target.value;
        setPrincipalExposures([...array]);
    };

    const exposureConterpartyBICChange = index => (e) => {
        let array = principalExposures;
        array[index].counterpartyEntityBIC = e.target.value;
        setPrincipalExposures([...array]);
    };

    const exposureConterpartyLEIChange = index => (e) => {
        let array = principalExposures;
        array[index].counterpartyEntityLEI = e.target.value;
        setPrincipalExposures([...array]);
    };

    const shareClassNationalCodeChange = index => (e) => {
        let array = shareClassIdentifiers;
        array[index].shareClassNationalCode = e.target.value;
        setShareClassIdentifiers([...array]);
    };

    const shareClassIdentifierISINChange = index => (e) => {
        let array = shareClassIdentifiers;
        array[index].shareClassIdentifierISIN = e.target.value;
        setShareClassIdentifiers([...array]);
    };

    const shareClassIdentifierCUSIPChange = index => (e) => {
        let array = shareClassIdentifiers;
        array[index].shareClassIdentifierCUSIP = e.target.value;
        setShareClassIdentifiers([...array]);
    };

    const shareClassIdentifierSEDOLChange = index => (e) => {
        let array = shareClassIdentifiers;
        array[index].shareClassIdentifierSEDOL = e.target.value;
        setShareClassIdentifiers([...array]);
    };

    const shareClassIdentifierTickerChange = index => (e) => {
        let array = shareClassIdentifiers;
        array[index].shareClassIdentifierTicker = e.target.value;
        setShareClassIdentifiers([...array]);
    };

    const shareClassIdentifierRICChange = index => (e) => {
        let array = shareClassIdentifiers;
        array[index].shareClassIdentifierRIC = e.target.value;
        setShareClassIdentifiers([...array]);
    };

    const shareClassNameChange = index => (e) => {
        let array = shareClassIdentifiers;
        array[index].shareClassName = e.target.value;
        setShareClassIdentifiers([...array]);
    };

    const marketRiskMeasureTypeChange = index => (selectedObject) => {
        let array = marketRiskMeasures;
        array[index].riskMeasureType = selectedObject === undefined ? {} : selectedObject;
        setMarketRiskMeasures([...array]);
    };

    const marketRiskMeasureValueChange = index => (e) => {
        let array = marketRiskMeasures;
        array[index].riskMeasureValue = e.target.value;
        setMarketRiskMeasures([...array]);
    };

    const marketRiskMeasureDescriptionChange = index => (e) => {
        let array = marketRiskMeasures;
        array[index].riskMeasureDescription = e.target.value;
        setMarketRiskMeasures([...array]);
    }

    const lessFiveYearsRiskMeasureValueChange = index => (e) => {
        let array = marketRiskMeasures;
        array[index].lessFiveYearsRiskMeasureValue = e.target.value;
        setMarketRiskMeasures([...array]);
    }
    const fifthteenYearsRiskMeasureValueChange = index => (e) => {
        let array = marketRiskMeasures;
        array[index].fifthteenYearsRiskMeasureValue = e.target.value;
        setMarketRiskMeasures([...array]);
    }
    const moreFifthteenYearsRiskMeasureValueChange = index => (e) => {
        let array = marketRiskMeasures;
        array[index].moreFifthteenYearsRiskMeasureValue = e.target.value;
        setMarketRiskMeasures([...array]);
    }
    const currentMarketRiskMeasureValueChange = index => (e) => {
        let array = marketRiskMeasures;
        array[index].currentMarketRiskMeasureValue = e.target.value;
        setMarketRiskMeasures([...array]);
    }
    const lowerMarketRiskMeasureValueChange = index => (e) => {
        let array = marketRiskMeasures;
        array[index].lowerMarketRiskMeasureValue = e.target.value;
        setMarketRiskMeasures([...array]);
    }
    const higherMarketRiskMeasureValueChange = index => (e) => {
        let array = marketRiskMeasures;
        array[index].higherMarketRiskMeasureValue = e.target.value;
        setMarketRiskMeasures([...array]);
    }
    const varValueChange = index => (e) => {
        let array = marketRiskMeasures;
        array[index].varValueChange = e.target.value;
        setMarketRiskMeasures([...array]);
    }
    const varCalculationMethodCodeTypeChange = index => (e) => {
        let array = marketRiskMeasures;
        array[index].varCalculationMethodCodeType = e.target.value;
        setMarketRiskMeasures([...array]);
    }

    function handleAddMarketRiskMeasure() {
        let array = marketRiskMeasures;
        array.push({
            riskMeasureType: undefined,
            riskMeasureValue: undefined,
            bucketRiskMeasureValues: {
                lessFiveYearsRiskMeasureValue: undefined,
                fifthteenYearsRiskMeasureValue: undefined,
                moreFifthteenYearsRiskMeasureValue: undefined
            },
            vegaRiskMeasureValues: {
                currentMarketRiskMeasureValue: undefined,
                lowerMarketRiskMeasureValue: undefined,
                higherMarketRiskMeasureValue: undefined
            },
            varRiskMeasureValues: {
                varValue: undefined,
                varCalculationMethodCodeType: undefined
            },
            riskMeasureDescription: undefined
        });
        setMarketRiskMeasures([...array]);
    }

    function handleDeleteMarketRiskMeasure(index) {
        let array = marketRiskMeasures;
        array.splice(index, 1);
        setMarketRiskMeasures([...array]);
    }

    const aifPrincipalMarketsFormat = () => {
        let array = [];

        for (let i = 0; i < aifPrincipalMarkets.length; i++) {
            let obj = {
                AIFPrincipalMarket: {
                    Ranking: aifPrincipalMarkets[i].ranking,
                    MarketIdentification: {
                        MarketCodeType: "NOT"
                    }
                }
            };
            if (aifPrincipalMarkets[i].aggregatedValueAmount !== undefined
                && aifPrincipalMarkets[i].marketType.value !== "NOT") {
                obj.AIFPrincipalMarket.MarketIdentification.MarketCodeType = aifPrincipalMarkets[i].marketType.value;
                obj.AIFPrincipalMarket.MarketIdentification.MarketCode = aifPrincipalMarkets[i].marketCode;
                obj.AIFPrincipalMarket.AggregatedValueAmount = aifPrincipalMarkets[i].aggregatedValueAmount;
            }
            array.push(obj);
        }
        return array;
    }

    const investmentStrategiesFormat = () => {
        let array = [];

        for (let i = 0; i < investmentStrategies.length; i++) {
            if (investmentStrategies[i].strategyType !== undefined &&
                investmentStrategies[i].isPrimary !== undefined &&
                investmentStrategies[i].navRate !== undefined) {
                let obj;
                if (selectedPredominantAIFType.value === 'HFND') {
                    obj = {
                        HedgeFundInvestmentStrategy: {
                            HedgeFundStrategyType: investmentStrategies[i].strategyType.value,
                            PrimaryStrategyFlag: investmentStrategies[i].isPrimary.value,
                            StrategyNAVRate: investmentStrategies[i].navRate,
                        }
                    };
                } else if (selectedPredominantAIFType.value === 'PEQF') {
                    obj = {
                        PrivateEquityFundInvestmentStrategy: {
                            PrivateEquityFundStrategyType: investmentStrategies[i].strategyType.value,
                            PrimaryStrategyFlag: investmentStrategies[i].isPrimary.value,
                            StrategyNAVRate: investmentStrategies[i].navRate,
                        }
                    };
                } else if (selectedPredominantAIFType.value === 'REST') {
                    obj = {
                        RealEstateFundInvestmentStrategy: {
                            RealEstateFundStrategyType: investmentStrategies[i].strategyType.value,
                            PrimaryStrategyFlag: investmentStrategies[i].isPrimary.value,
                            StrategyNAVRate: investmentStrategies[i].navRate,
                        }
                    };
                } else if (selectedPredominantAIFType.value === 'FOFS') {
                    obj = {
                        FundOfFundsInvestmentStrategy: {
                            FundOfFundsStrategyType: investmentStrategies[i].strategyType.value,
                            PrimaryStrategyFlag: investmentStrategies[i].isPrimary.value,
                            StrategyNAVRate: investmentStrategies[i].navRate,
                        }
                    };
                } else if (selectedPredominantAIFType.value === 'OTHR') {
                    obj = {
                        OtherFundInvestmentStrategy: {
                            OtherFundStrategyType: investmentStrategies[i].strategyType.value,
                            PrimaryStrategyFlag: investmentStrategies[i].isPrimary.value,
                            StrategyNAVRate: investmentStrategies[i].navRate,
                        }
                    };
                } else {
                    obj = {
                        NoneFundInvestmentStrategy: {
                            NoneFundStrategyType: investmentStrategies[i].strategyType.value,
                            PrimaryStrategyFlag: investmentStrategies[i].isPrimary.value,
                            StrategyNAVRate: investmentStrategies[i].navRate,
                        }
                    };
                }
                array.push(obj);
            }
        }
        return array;
    }

    const mainInstrumentsTradedFormat = () => {
        let array = [];

        for (let i = 0; i < mainInstrumentsTraded.length; i++) {
            let obj = {
                MainInstrumentTraded: {
                    Ranking: mainInstrumentsTraded[i].ranking,
                    SubAssetType: "NTA_NTA_NOTA",
                    PositionValue: 0
                }
            };
            if (mainInstrumentsTraded[i].subAssetType.value !== "NTA_NTA_NOTA"
                && mainInstrumentsTraded[i].positionValue !== undefined) {
                obj.MainInstrumentTraded.SubAssetType = mainInstrumentsTraded[i].subAssetType.value;
                obj.MainInstrumentTraded.PositionValue = mainInstrumentsTraded[i].positionValue;
            }
            array.push(obj);
        }
        return array;
    }

    const principalExposuresFormat = () => {
        let array = [];

        for (let i = 0; i < principalExposures.length; i++) {
            let obj = {
                PrincipalExposure: {
                    Ranking: principalExposures[i].ranking,
                    AssetMacroType: 'NTA'
                }
            };
            if (principalExposures[i].assetMacroType.value !== 'NTA'
                && principalExposures[i].subAssetType !== undefined
                && principalExposures[i].positionType !== undefined
                && principalExposures[i].aggregatedValueAmount !== undefined
                && principalExposures[i].aggregatedValueRate !== undefined) {
                obj.PrincipalExposure.AssetMacroType = principalExposures[i].assetMacroType.value;
                obj.PrincipalExposure.SubAssetType = principalExposures[i].subAssetType.value;
                obj.PrincipalExposure.PositionType = principalExposures[i].positionType.value;
                obj.PrincipalExposure.AggregatedValueAmount = principalExposures[i].aggregatedValueAmount;
                obj.PrincipalExposure.AggregatedValueRate = principalExposures[i].aggregatedValueRate;
                obj.PrincipalExposure.CounterpartyIdentification = {};
                if (principalExposures[i].counterpartyEntityName !== undefined && principalExposures[i].counterpartyEntityName !== '') {
                    obj.PrincipalExposure.CounterpartyIdentification.EntityName = principalExposures[i].counterpartyEntityName;
                }
                if (principalExposures[i].counterpartyEntityBIC !== undefined && principalExposures[i].counterpartyEntityBIC !== '') {
                    obj.PrincipalExposure.CounterpartyIdentification.EntityIdentificationBIC = principalExposures[i].counterpartyEntityBIC;
                }
                if (principalExposures[i].counterpartyEntityLEI !== undefined && principalExposures[i].counterpartyEntityLEI !== '') {
                    obj.PrincipalExposure.CounterpartyIdentification.EntityIdentificationLEI = principalExposures[i].counterpartyEntityLEI;
                }
                if ((principalExposures[i].counterpartyEntityName === undefined || principalExposures[i].counterpartyEntityName === '')
                    && (principalExposures[i].counterpartyEntityBIC === undefined || principalExposures[i].counterpartyEntityBIC === '')
                    && (principalExposures[i].counterpartyEntityLEI === undefined || principalExposures[i].counterpartyEntityLEI === '')) {
                    delete obj.PrincipalExposure.CounterpartyIdentification;
                }
            }
            array.push(obj);
        }
        return array;
    }

    const portfolioConcentrationsFormat = () => {
        let array = [];

        for (let i = 0; i < portfolioConcentrations.length; i++) {
            let obj = {
                PortfolioConcentration: {
                    Ranking: portfolioConcentrations[i].ranking,
                    AssetType: "NTA_NTA"
                }
            };
            if (portfolioConcentrations[i].assetType.value !== "NTA_NTA"
                && portfolioConcentrations[i].positionType !== undefined
                && portfolioConcentrations[i].marketType !== undefined
                && portfolioConcentrations[i].aggregatedValueAmount !== undefined
                && portfolioConcentrations[i].aggregatedValueRate !== undefined) {
                obj.PortfolioConcentration.AssetType = portfolioConcentrations[i].assetType.value;
                obj.PortfolioConcentration.PositionType = portfolioConcentrations[i].positionType.value;
                obj.PortfolioConcentration.MarketIdentification = {};
                obj.PortfolioConcentration.MarketIdentification.MarketCodeType = portfolioConcentrations[i].marketType.value;
                if (portfolioConcentrations[i].marketType.value === 'MIC') {
                    obj.PortfolioConcentration.MarketIdentification.MarketCode = portfolioConcentrations[i].marketCode;
                }
                obj.PortfolioConcentration.AggregatedValueAmount = portfolioConcentrations[i].aggregatedValueAmount;
                obj.PortfolioConcentration.AggregatedValueRate = portfolioConcentrations[i].aggregatedValueRate;
                if (portfolioConcentrations[i].marketType.value === 'OTC') {
                    obj.PortfolioConcentration.CounterpartyIdentification = {};
                    if (portfolioConcentrations[i].counterpartyEntityName !== undefined && portfolioConcentrations[i].counterpartyEntityName !== '') {
                        obj.PortfolioConcentration.CounterpartyIdentification.EntityName = portfolioConcentrations[i].counterpartyEntityName;
                    }
                    if (portfolioConcentrations[i].counterpartyEntityBIC !== undefined && portfolioConcentrations[i].counterpartyEntityBIC !== '') {
                        obj.PortfolioConcentration.CounterpartyIdentification.EntityIdentificationBIC = portfolioConcentrations[i].counterpartyEntityBIC;
                    }
                    if (portfolioConcentrations[i].counterpartyEntityLEI !== undefined && portfolioConcentrations[i].counterpartyEntityLEI !== '') {
                        obj.PortfolioConcentration.CounterpartyIdentification.EntityIdentificationLEI = portfolioConcentrations[i].counterpartyEntityLEI;
                    }
                    if ((portfolioConcentrations[i].counterpartyEntityName === undefined || portfolioConcentrations[i].counterpartyEntityName === '')
                        && (portfolioConcentrations[i].counterpartyEntityBIC === undefined || portfolioConcentrations[i].counterpartyEntityBIC === '')
                        && (portfolioConcentrations[i].counterpartyEntityLEI === undefined || portfolioConcentrations[i].counterpartyEntityLEI === '')) {
                        delete obj.PortfolioConcentration.CounterpartyIdentification;
                    }
                }
            }
            array.push(obj);
        }
        return array;
    }

    const getCurrencyBaseDescription = (type) => {
        let obj;
        if (type === 'AIFM') {
            obj = {
                "AIFMBaseCurrencyDescription": {
                    "BaseCurrency": selectedBaseCurrency.value,
                    "AUMAmountInBaseCurrency": aumAmountInBaseCurrency,
                    "FXEURReferenceRateType": selectedFXEURReferenceRateType.value,
                    "FXEURRate": fxEURRate,
                }
            }
        } else {
            obj = {
                "AIFBaseCurrencyDescription": {
                    "BaseCurrency": selectedBaseCurrency.value,
                    "AUMAmountInBaseCurrency": aumAmountInBaseCurrency,
                    "FXEURReferenceRateType": selectedFXEURReferenceRateType.value,
                    "FXEURRate": fxEURRate,
                }
            }
        }

        if (selectedFXEURReferenceRateType.value === 'OTH') {
            obj.FXEUROtherReferenceRateDescription = fxEUROtherReferenceRateDescription;
        }

        return obj;
    }

    const shareClassIdentifiersFormat = () => {
        let array = [];

        for (let i = 0; i < shareClassIdentifiers.length; i++) {
            let obj = {
                ShareClassIdentifier: {
                    ShareClassNationalCode: shareClassIdentifiers[i].shareClassNationalCode,
                    ShareClassIdentifierISIN: shareClassIdentifiers[i].shareClassIdentifierISIN,
                    ShareClassIdentifierCUSIP: shareClassIdentifiers[i].shareClassIdentifierCUSIP,
                    ShareClassIdentifierSEDOL: shareClassIdentifiers[i].shareClassIdentifierSEDOL,
                    ShareClassIdentifierTicker: shareClassIdentifiers[i].shareClassIdentifierTicker,
                    ShareClassIdentifierRIC: shareClassIdentifiers[i].shareClassIdentifierRIC,
                    ShareClassName: shareClassIdentifiers[i].shareClassName
                }
            };

            array.push(obj);
        }
        return array;
    }

    const getAIFIndividualInfo = () => {
        if (selectedAIFContentType.value === "2" || selectedAIFContentType.value === "4") {
            let obj = {
                AIFIndividualInfo: {
                    IndividualExposure: "fake",
                    RiskProfile: {
                        MarketRiskProfile: {
                            AnnualInvestmentReturnRate: annualInvestmentReturnRate
                        }
                    },
                    StressTests: {
                        StressTestsResultArticle15: stressTestsResultArticle15,
                        StressTestsResultArticle16: stressTestsResultArticle16
                    }
                }
            };

            return obj;
        } else {
            return {
                AIFIndividualInfo: null
            }
        }
    }

    const getAIFLeverageInfo = () => {
        if (selectedAIFContentType.value === "4" || selectedAIFContentType.value === "5") {
            let obj = {
                AIFLeverageInfo: {
                    test: "fake"
                }
            };

            return obj;
        } else {
            return {
                AIFLeverageInfo: null
            }
        }
    }

    function parseXML(obj) {
        const aifRecordInfoObj = obj.AIFReportingInfo.AIFRecordInfo;
        setFilingType(filingTypesJson.find((e) => e.value === aifRecordInfoObj.FilingType));
        setSelectedAIFContentType(AIFContentTypesJson.find((e) => e.value === aifRecordInfoObj.AIFContentType));
        setReportingPeriodStartDate(new Date(aifRecordInfoObj.ReportingPeriodStartDate));
        setReportingPeriodEndDate(new Date(aifRecordInfoObj.ReportingPeriodEndDate));
        setSelectedReportPeriod(reportPeriodsJson.find((e) => e.value === aifRecordInfoObj.ReportingPeriodType));
        setReportingPeriodYear(new Date(aifRecordInfoObj.ReportingPeriodYear));
        setSelectedLastReportingFlag(booleanChoiceJson.find((e) => e.label.toUpperCase() === aifRecordInfoObj.LastReportingFlag.toUpperCase()));
        setAIFMNationalCode(aifRecordInfoObj.AIFNationalCode);
        setAIFNationalCode(aifRecordInfoObj.AIFNationalCode);
        setAIFName(aifRecordInfoObj.AIFName);
        setSelectedAIFEEAFlag(booleanChoiceJson.find((e) => e.label.toUpperCase() === aifRecordInfoObj.AIFEEAFlag.toUpperCase()));
        setSelectedAIFReportingCode(AIFContentTypesJson.find((e) => e.value === aifRecordInfoObj.AIFReportingCode));
        setAIFDomicile(aifRecordInfoObj.AIFDomicile);
        setInceptionDate(new Date(aifRecordInfoObj.InceptionDate));
        setSelectedAIFNoReportingFlag(booleanChoiceJson.find((e) => e.label.toUpperCase() === aifRecordInfoObj.AIFNoReportingFlag.toUpperCase()));

        const principalInfo = aifRecordInfoObj.AIFCompleteDescription.AIFPrincipalInfo;
        setSelectedShareClassFlag(booleanChoiceJson.find((e) => e.label.toUpperCase() === principalInfo.ShareClassFlag.toUpperCase()));

        const aifDescription = principalInfo.AIFDescription;
        setSelectedAIFMasterFeederStatus(AIFMasterFeederStatusTypesJson.find((e) => e.value === aifDescription.AIFMasterFeederStatus));
        const aifBaseCurrencyDescription = aifDescription.AIFBaseCurrencyDescription;
        setSelectedBaseCurrency(baseCurrenciesJson.find((e) => e.value === aifBaseCurrencyDescription.BaseCurrency));
        setAUMAmountInBaseCurrency(aifBaseCurrencyDescription.AUMAmountInBaseCurrency);
        setSelectedFXEURReferenceRateType(fxEURReferenceRateTypesJson.find((e) => e.value === aifBaseCurrencyDescription.FXEURReferenceRateType));
        setFXEURRate(aifBaseCurrencyDescription.FXEURRate);
        setFXEUROtherReferenceRateDescription(aifBaseCurrencyDescription.FXEUROtherReferenceRateDescription);
        setAIFNetAssetValue(aifDescription.AIFNetAssetValue);
        setFirstFundingSourceCountry(countriesISOCodesJson.find((e) => e.value === aifDescription.FirstFundingSourceCountry));
        setSecondFundingSourceCountry(countriesISOCodesJson.find((e) => e.value === aifDescription.SecondFundingSourceCountry));
        setThirdFundingSourceCountry(countriesISOCodesJson.find((e) => e.value === aifDescription.ThirdFundingSourceCountry));
        setSelectedPredominantAIFType(aifDescription.PredominantAIFType !== undefined ? predominantAIFTypesJson.find((e) => e.value === aifDescription.PredominantAIFType) : { value: null, label: '' });
        setHFTTransactionNumber(aifDescription.HFTTransactionNumber);
        setHFTBuySellMarketValue(aifDescription.HFTBuySellMarketValue);

        const aifIndividualInfo = aifRecordInfoObj.AIFCompleteDescription.AIFIndividualInfo;
        if (aifIndividualInfo !== undefined) {
            const riskProfile = aifIndividualInfo.RiskProfile;
            setAnnualInvestmentReturnRate(riskProfile.MarketRiskProfile.AnnualInvestmentReturnRate);
            const stressTests = aifIndividualInfo.StressTests;
            setStressTestsResultArticle15(stressTests.StressTestsResultArticle15);
            setStressTestsResultArticle16(stressTests.StressTestsResultArticle16);
        }

        let array = [];
        if (aifDescription.HedgeFundInvestmentStrategies !== undefined) {
            const hfndStrategies = aifDescription.HedgeFundInvestmentStrategies.HedgeFundInvestmentStrategy;
            for (let i = 0; i < hfndStrategies.length; i++) {
                let strategyType;
                for (let j = 0; j < strategiesListJson.length; j++) {
                    strategyType = strategiesListJson[j].options.find((e) => e.value === hfndStrategies[i].HedgeFundStrategyType);
                    if (strategyType !== undefined)
                        break;
                }
                let obj = {
                    strategyType: strategyType,
                    isPrimary: booleanChoiceJson.find((e) => e.label.toUpperCase() === hfndStrategies[i].PrimaryStrategyFlag.toUpperCase()),
                    navRate: hfndStrategies[i].StrategyNAVRate
                };
                array.push(obj);
            }
        }
        if (aifDescription.PrivateEquityFundInvestmentStrategies !== undefined) {
            if (aifDescription.PrivateEquityFundInvestmentStrategies.PrivateEquityFundInvestmentStrategy !== undefined) {
                const peqfStrategies = aifDescription.PrivateEquityFundInvestmentStrategies.PrivateEquityFundInvestmentStrategy;
                for (let i = 0; i < peqfStrategies.length; i++) {
                    let strategyType;
                    for (let j = 0; j < strategiesListJson.length; j++) {
                        strategyType = strategiesListJson[j].options.find((e) => e.value === peqfStrategies[i].PrivateEquityFundStrategyType);
                        if (strategyType !== undefined)
                            break;
                    }
                    let obj = {
                        strategyType: strategyType,
                        isPrimary: booleanChoiceJson.find((e) => e.label.toUpperCase() === peqfStrategies[i].PrimaryStrategyFlag.toUpperCase()),
                        navRate: peqfStrategies[i].StrategyNAVRate
                    };
                    array.push(obj);
                }
            }
        }
        if (aifDescription.RealEstateFundInvestmentStrategies !== undefined) {
            if (aifDescription.RealEstateFundInvestmentStrategies.RealEstateFundInvestmentStrategy !== undefined) {
                const restStrategies = aifDescription.RealEstateFundInvestmentStrategies.RealEstateFundInvestmentStrategy;
                for (let i = 0; i < restStrategies.length; i++) {
                    let strategyType;
                    for (let j = 0; j < strategiesListJson.length; j++) {
                        strategyType = strategiesListJson[j].options.find((e) => e.value === restStrategies[i].RestFundStrategyType);
                        if (strategyType !== undefined)
                            break;
                    }
                    let obj = {
                        strategyType: strategyType,
                        isPrimary: booleanChoiceJson.find((e) => e.label.toUpperCase() === restStrategies[i].PrimaryStrategyFlag.toUpperCase()),
                        navRate: restStrategies[i].StrategyNAVRate
                    };
                    array.push(obj);
                }
            }
        }
        if (aifDescription.FundOfFundsInvestmentStrategies !== undefined) {
            if (aifDescription.FundOfFundsInvestmentStrategies.FundOfFundsInvestmentStrategy !== undefined) {
                const fofsStrategies = aifDescription.FundOfFundsInvestmentStrategies.FundOfFundsInvestmentStrategy;
                for (let i = 0; i < fofsStrategies.length; i++) {
                    let strategyType;
                    for (let j = 0; j < strategiesListJson.length; j++) {
                        strategyType = strategiesListJson[j].options.find((e) => e.value === fofsStrategies[i].FundOfFundsStrategyType);
                        if (strategyType !== undefined)
                            break;
                    }
                    let obj = {
                        strategyType: strategyType,
                        isPrimary: booleanChoiceJson.find((e) => e.label.toUpperCase() === fofsStrategies[i].PrimaryStrategyFlag.toUpperCase()),
                        navRate: fofsStrategies[i].StrategyNAVRate
                    };
                    array.push(obj);
                }
            }
        }
        if (aifDescription.OtherFundInvestmentStrategies !== undefined) {
            if (aifDescription.OtherFundInvestmentStrategies.OtherFundInvestmentStrategy !== undefined) {
                const othrStrategies = aifDescription.OtherFundInvestmentStrategies.OtherFundInvestmentStrategy;
                for (let i = 0; i < othrStrategies.length; i++) {
                    let strategyType;
                    for (let j = 0; j < strategiesListJson.length; j++) {
                        strategyType = strategiesListJson[j].options.find((e) => e.value === othrStrategies[i].OtherFundStrategyType);
                        if (strategyType !== undefined)
                            break;
                    }
                    let obj = {
                        strategyType: strategyType,
                        isPrimary: booleanChoiceJson.find((e) => e.label.toUpperCase() === othrStrategies[i].PrimaryStrategyFlag.toUpperCase()),
                        navRate: othrStrategies[i].StrategyNAVRate
                    };
                    array.push(obj);
                }
            }
        }
        if (aifDescription.NoneFundInvestmentStrategies !== undefined) {
            if (aifDescription.NoneFundInvestmentStrategies.NoneFundInvestmentStrategy !== undefined) {
                const noneStrategies = aifDescription.NoneFundInvestmentStrategies.NoneFundInvestmentStrategy;
                for (let i = 0; i < noneStrategies.length; i++) {
                    let strategyType;
                    for (let j = 0; j < strategiesListJson.length; j++) {
                        strategyType = strategiesListJson[j].options.find((e) => e.value === noneStrategies[i].NoneFundStrategyType);
                        if (strategyType !== undefined)
                            break;
                    }
                    let obj = {
                        strategyType: strategyType,
                        isPrimary: booleanChoiceJson.find((e) => e.label.toUpperCase() === noneStrategies[i].PrimaryStrategyFlag.toUpperCase()),
                        navRate: noneStrategies[i].StrategyNAVRate
                    };
                    array.push(obj);
                }
            }
        }
        setInvestmentStrategies(array);

        if (principalInfo.MainInstrumentsTraded !== undefined) {
            const mainInstrumentsTraded = principalInfo.MainInstrumentsTraded.MainInstrumentTraded;
            array = [];
            for (let i = 0; i < mainInstrumentsTraded.length; i++) {
                let subAssetType;
                for (let j = 0; j < subAssetsJson.length; j++) {
                    subAssetType = subAssetsJson[j].options.find((e) => e.value === mainInstrumentsTraded[i].SubAssetType);
                    if (subAssetType !== undefined)
                        break;
                }
                let obj = {
                    ranking: mainInstrumentsTraded[i].Ranking,
                    subAssetType: subAssetType === undefined ? { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" } : subAssetType,
                    instrumentName: mainInstrumentsTraded[i].InstrumentName,
                    instrumentCodeType: instrumentCodeTypesJson.find((e) => e.value === mainInstrumentsTraded[i].InstrumentCodeType),
                    instrumentCode: mainInstrumentsTraded[i].InstrumentCodeType === 'ISIN' ? mainInstrumentsTraded[i].ISINInstrumentIdentification : mainInstrumentsTraded[i].AIIInstrumentIdentification,
                    positionValue: mainInstrumentsTraded[i].PositionValue,
                    positionType: positionTypesJson.find((e) => e.value === mainInstrumentsTraded[i].PositionType),
                    shortPositionHedgingRate: mainInstrumentsTraded[i].ShortPositionHedgingRate
                };
                array.push(obj);
            }
            setMainInstrumentsTraded(array);
        }

        const navGeographicalFocus = principalInfo.NAVGeographicalFocus;
        if (navGeographicalFocus !== undefined) {
            setAfricaNAVRate(navGeographicalFocus.AfricaNAVRate);
            setAsiaPacificNAVRate(navGeographicalFocus.AsiaPacificNAVRate);
            setEuropeNAVRate(navGeographicalFocus.EuropeNAVRate);
            setEEANAVRate(navGeographicalFocus.EEANAVRate);
            setMiddleEastNAVRate(navGeographicalFocus.MiddleEastNAVRate);
            setNorthAmericaNAVRate(navGeographicalFocus.NorthAmericaNAVRate);
            setSouthAmericaNAVRate(navGeographicalFocus.SouthAmericaNAVRate);
            setSupraNationalNAVRate(navGeographicalFocus.SupraNationalNAVRate);
        }

        const aumGeographicalFocus = principalInfo.AUMGeographicalFocus;
        if (aumGeographicalFocus !== undefined) {
            setAfricaAUMRate(aumGeographicalFocus.AfricaAUMRate);
            setAsiaPacificAUMRate(aumGeographicalFocus.AsiaPacificAUMRate);
            setEuropeAUMRate(aumGeographicalFocus.EuropeAUMRate);
            setEEAAUMRate(aumGeographicalFocus.EEAAUMRate);
            setMiddleEastAUMRate(aumGeographicalFocus.MiddleEastAUMRate);
            setNorthAmericaAUMRate(aumGeographicalFocus.NorthAmericaAUMRate);
            setSouthAmericaAUMRate(aumGeographicalFocus.SouthAmericaAUMRate);
            setSupraNationalAUMRate(aumGeographicalFocus.SupraNationalAUMRate);
        }

        if (principalInfo.PrincipalExposures !== undefined) {
            const principalExposures = principalInfo.PrincipalExposures.PrincipalExposure;
            array = [];
            for (let i = 0; i < principalExposures.length; i++) {
                let assetMacroType = assetMacroTypesJson.find((e) => e.value === principalExposures[i].AssetMacroType);
                let subAssetType;
                for (let j = 0; j < subAssetsJson.length; j++) {
                    subAssetType = subAssetsJson[j].options.find((e) => e.value === principalExposures[i].SubAssetType);
                    if (subAssetType !== undefined)
                        break;
                }
                let obj = {
                    ranking: principalExposures[i].Ranking,
                    assetMacroType: assetMacroType === undefined ? { value: 'NTA', label: 'NTA' } : assetMacroType,
                    subAssetType: subAssetType === undefined ? { value: "NTA_NTA_NOTA", label: "NTA_NTA_NOTA" } : subAssetType,
                    positionType: positionTypesJson.find((e) => e.value === principalExposures[i].PositionType),
                    aggregatedValueAmount: principalExposures[i].AggregatedValueAmount,
                    aggregatedValueRate: principalExposures[i].AggregatedValueRate,
                };
                if (principalExposures[i].CounterpartyIdentification !== undefined) {
                    obj.counterpartyEntityName = principalExposures[i].CounterpartyIdentification.EntityName;
                    obj.counterpartyEntityBIC = principalExposures[i].CounterpartyIdentification.EntityBIC;
                    obj.counterpartyEntityLEI = principalExposures[i].CounterpartyIdentification.EntityLEI;
                }
                array.push(obj);
            }
            setPrincipalExposures(array);
        }

        if (principalInfo.MostImportantConcentration !== undefined) {
            if (principalInfo.MostImportantConcentration.PortfolioConcentrations !== undefined) {
                const portfolioConcentrations = principalInfo.MostImportantConcentration.PortfolioConcentrations.PortfolioConcentration;
                array = [];
                for (let i = 0; i < portfolioConcentrations.length; i++) {
                    let assetType = assetTypesJson.find((e) => e.value === portfolioConcentrations[i].AssetType);
                    let obj = {
                        ranking: portfolioConcentrations[i].Ranking,
                        assetType: assetType === undefined ? { value: "NTA_NTA", label: "NTA_NTA" } : assetType,
                        marketType: { value: '', label: null },
                        positionType: positionTypesJson.find((e) => e.value === portfolioConcentrations[i].PositionType),
                        aggregatedValueAmount: portfolioConcentrations[i].AggregatedValueAmount,
                        aggregatedValueRate: portfolioConcentrations[i].AggregatedValueRate,
                    };

                    if (portfolioConcentrations[i].MarketIdentification !== undefined) {
                        let marketType = marketTypesJson.find((e) => e.value === portfolioConcentrations[i].MarketIdentification.MarketCodeType);

                        obj.marketType = marketType === undefined ? { value: '', label: null } : marketType;
                        obj.marketCode = portfolioConcentrations[i].MarketIdentification.MarketCode;
                    }

                    if (portfolioConcentrations[i].CounterpartyIdentification !== undefined) {
                        obj.counterpartyEntityName = portfolioConcentrations[i].CounterpartyIdentification.EntityName;
                        obj.counterpartyEntityBIC = portfolioConcentrations[i].CounterpartyIdentification.EntityBIC;
                        obj.counterpartyEntityLEI = portfolioConcentrations[i].CounterpartyIdentification.EntityLEI;
                    }
                    array.push(obj);
                }
                setPortfolioConcentrations(array);
            }

            const typicalPositionSize = principalInfo.MostImportantConcentration.TypicalPositionSize;
            let positionSize = positionSizesJson.find((e) => e.value === typicalPositionSize);
            setSelectedTypicalPositionSize(positionSize === undefined ? { value: "V_SMALL", label: "Very small (< 5 mln EUR)" } : positionSize);

            const principalMarkets = principalInfo.MostImportantConcentration.AIFPrincipalMarkets.AIFPrincipalMarket;
            array = [];
            for (let i = 0; i < principalMarkets.length; i++) {
                let marketType = marketTypesJson.find((e) => e.value === principalMarkets[i].MarketIdentification.MarketCodeType);
                let obj = {
                    ranking: principalMarkets[i].Ranking,
                    marketType: marketType === undefined ? { value: '', label: null } : marketType,
                    marketCode: principalMarkets[i].MarketIdentification.MarketCode,
                    aggregatedValueAmount: principalMarkets[i].AggregatedValueAmount,
                };
                array.push(obj);
            }
            setAIFPrincipalMarkets(array);

            const investorConcentration = principalInfo.MostImportantConcentration.InvestorConcentration;
            setMainBeneficialOwnersRate(investorConcentration.MainBeneficialOwnersRate);
            setProfessionalInvestorConcentrationRate(investorConcentration.ProfessionalInvestorConcentrationRate);
            setRetailInvestorConcentrationRate(investorConcentration.RetailInvestorConcentrationRate);
        }
    }

    function handleCreateXMLClick() {
        const xmlDoc = create({ encoding: "UTF-8" });
        const xsi = 'http://www.w3.org/2001/XMLSchema-instance';
        const nsDataif = 'AIFMD_DATAIF_V1.2.xsd';

        const aifReportingInfo = {
            "AIFReportingInfo": {
                '@CreationDateAndTime': formatDate(new Date(), 'dt'),
                '@Version': 1.2,
                '@ReportingMemberState': 'PL'
            }
        };

        const aifRecordInfo = {
            "AIFRecordInfo": {
                "FilingType": filingType.value,
                "AIFContentType": selectedAIFContentType.value,
                "ReportingPeriodStartDate": formatDate(reportingPeriodStartDate, 'd'),
                "ReportingPeriodEndDate": formatDate(reportingPeriodEndDate, 'd'),
                "ReportingPeriodType": selectedReportPeriod.value,
                "ReportingPeriodYear": formatDate(reportingPeriodYear, 'y'),
                "LastReportingFlag": selectedLastReportingFlag.value,
                "AIFMNationalCode": aifmNationalCode,
                "AIFNationalCode": aifNationalCode,
                "AIFName": aifName,
                "AIFEEAFlag": selectedAIFEEAFlag.value,
                "AIFReportingCode": selectedAIFReportingCode.value,
                "AIFDomicile": aifDomicile,
                "InceptionDate": formatDate(inceptionDate, 'd'),
                "AIFNoReportingFlag": selectedAIFNoReportingFlag.value
            }
        };

        const aifFundingSourceCountries = {
            "FirstFundingSourceCountry": firstFundingSourceCountry ? firstFundingSourceCountry.value : null,
            "SecondFundingSourceCountry": secondFundingSourceCountry ? secondFundingSourceCountry.value : null,
            "ThirdFundingSourceCountry": thirdFundingSourceCountry ? thirdFundingSourceCountry.value : null
        }

        const hftElements = {
            "HFTTransactionNumber": hftTransactionNumber,
            "HFTBuySellMarketValue": hftBuySellMarketValue
        }

        const aifDescriptionXMLFragment = fragment({ encoding: "UTF-8" });
        aifDescriptionXMLFragment
            .ele("AIFDescription")
            .ele("AIFMasterFeederStatus").txt(selectedAIFMasterFeederStatus.value).up()
            .ele(getCurrencyBaseDescription('AIF')).up()
            .ele("AIFNetAssetValue").txt(aifNetAssetValue).up()
            .ele(aifFundingSourceCountries).up()
            .ele("PredominantAIFType").txt(selectedPredominantAIFType.value).up()
            .ele(selectedPredominantAIFType.type + "InvestmentStrategies")
            .ele(investmentStrategiesFormat()).up().up()
            .ele(hftElements).up();

        if (!selectedAIFNoReportingFlag.value) {
            xmlDoc
                .ele(aifReportingInfo).att(xsi, 'xsi:noNamespaceSchemaLocation', nsDataif)
                .ele(aifRecordInfo)
                .ele("AIFCompleteDescription")
                .ele("AIFPrincipalInfo")
                .ele("ShareClassFlag").txt(selectedShareClassFlag.value).up()
                .ele("ShareClassIdentification").txt(selectedShareClassFlag.value ? shareClassIdentifiersFormat() : null).up()
                .import(aifDescriptionXMLFragment)
                .ele("MainInstrumentsTraded")
                .ele(mainInstrumentsTradedFormat()).up().up()
                .ele("NAVGeographicalFocus")
                .ele("AfricaNAVRate").txt(africaNAVRate).up()
                .ele("AsiaPacificNAVRate").txt(asiaPacificNAVRate).up()
                .ele("EuropeNAVRate").txt(europeNAVRate).up()
                .ele("EEANAVRate").txt(eEANAVRate).up()
                .ele("MiddleEastNAVRate").txt(middleEastNAVRate).up()
                .ele("NorthAmericaNAVRate").txt(northAmericaNAVRate).up()
                .ele("SouthAmericaNAVRate").txt(southAmericaNAVRate).up()
                .ele("SupraNationalNAVRate").txt(supraNationalNAVRate).up().up()
                .ele("AUMGeographicalFocus")
                .ele("AfricaAUMRate").txt(africaAUMRate).up()
                .ele("AsiaPacificAUMRate").txt(asiaPacificAUMRate).up()
                .ele("EuropeAUMRate").txt(europeAUMRate).up()
                .ele("EEAAUMRate").txt(eEAAUMRate).up()
                .ele("MiddleEastAUMRate").txt(middleEastAUMRate).up()
                .ele("NorthAmericaAUMRate").txt(northAmericaAUMRate).up()
                .ele("SouthAmericaAUMRate").txt(southAmericaAUMRate).up()
                .ele("SupraNationalAUMRate").txt(supraNationalAUMRate).up().up()
                .ele("PrincipalExposures")
                .ele(principalExposuresFormat()).up().up()
                .ele("MostImportantConcentration")
                .ele("PortfolioConcentrations")
                .ele(portfolioConcentrationsFormat()).up().up()
                .ele("TypicalPositionSize").txt(selectedTypicalPositionSize.value).up()
                .ele("AIFPrincipalMarkets")
                .ele(aifPrincipalMarketsFormat()).up().up()
                .ele("InvestorConcentration")
                .ele("MainBeneficialOwnersRate").txt(mainBeneficialOwnersRate).up()
                .ele("ProfessionalInvestorConcentrationRate").txt(professionalInvestorConcentrationRate).up()
                .ele("RetailInvestorConcentrationRate").txt(retailInvestorConcentrationRate).up().up()
                .ele(getAIFIndividualInfo()).up()
                .ele(getAIFLeverageInfo()).up().up().up();

            const compl = xmlDoc.end({ prettyPrint: true });

            const element = document.createElement("a");
            const file = new Blob([compl], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = aifmNationalCode + "_" + aifNationalCode + "_DATAIF_" + formatDate(reportingPeriodYear, 'y') + "_" + selectedReportPeriod.value + "_000.xml";
            document.body.appendChild(element);
            element.click();
        } else {
            xmlDoc
                .ele(aifReportingInfo).att(xsi, 'xsi:noNamespaceSchemaLocation', nsDataif)
                .ele(aifRecordInfo);

            const compl = xmlDoc.end({ prettyPrint: true });

            const element = document.createElement("a");
            const file = new Blob([compl], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = aifmNationalCode + "_" + aifNationalCode + "_DATAIF_" + formatDate(reportingPeriodYear, 'y') + "_" + selectedReportPeriod.value + "_000.xml";
            document.body.appendChild(element);
            element.click();
        }
    }

    useEffect(() => {
        if (props.obj !== null && props.obj !== undefined) {
            parseXML(props.obj);
        }
        if (selectedPredominantAIFType.value === 'NONE') {
            setStrategyTypesToSelect(strategiesListJson);
        } else {
            setStrategyTypesToSelect(strategiesListJson.filter(e => { return e.value === selectedPredominantAIFType.value }));
        }
    }, [props.obj, selectedPredominantAIFType.value]);

    return (
        <PaneContent>
            <SectionWrapper
                title='Basic Info'>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'AIF Name'}
                        tooltip={tooltips.AIFName}
                    >
                        <input className="is-input" placeholder={'AIF Name'} value={aifName} onChange={(e) => setAIFName(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AIFM National Code'}
                        tooltip={tooltips.AIFMNationalCode}
                    >
                        <input className="is-input" placeholder="AIFM National Code" value={aifmNationalCode} onChange={(e) => setAIFMNationalCode(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AIF National Code'}
                        tooltip={tooltips.AIFNationalCode}
                    >
                        <input className="is-input" placeholder="AIF National Code" value={aifNationalCode} onChange={(e) => setAIFNationalCode(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AIF Domicile'}
                        tooltip={tooltips.AIFDomicile}
                    >
                        <input className="is-input" placeholder="AIF Domicile" value={aifDomicile} onChange={(e) => setAIFDomicile(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Inception Date'}
                        tooltip={tooltips.InceptionDate}
                    >
                        <DatePicker className="is-input" dateFormat="yyyy-MM-dd" selected={inceptionDate} onChange={(date) => setInceptionDate(date)} />
                    </OptionWrapper>
                </OptionsGroup>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'AIF Reporting Code'}
                        tooltip={tooltips.AIFReportingCode}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="AIF Reporting Code" value={selectedAIFReportingCode} options={aifReportingCodesJson} onChange={(selectedObject) => setSelectedAIFReportingCode(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AIF Content Type'}
                        tooltip={tooltips.AIFContentType}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="AIF Content Type" value={selectedAIFContentType} options={AIFContentTypesJson} onChange={(selectedObject) => setSelectedAIFContentType(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Last Reporting Flag'}
                        tooltip={tooltips.LastReportingFlag}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="Last Reporting Flag" value={selectedLastReportingFlag} options={[{ value: false, label: 'False' }, { value: true, label: 'True' }]} onChange={(selectedObject) => setSelectedLastReportingFlag(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AIF EEA Flag'}
                        tooltip={tooltips.AIFEEAFlag}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="AIF EEA Flag" value={selectedAIFEEAFlag} options={[{ value: false, label: 'False' }, { value: true, label: 'True' }]} onChange={(selectedObject) => setSelectedAIFEEAFlag(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AIF No Reporting Flag'}
                        tooltip={tooltips.AIFNoReportingFlag}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="AIF No Reporting Flag" value={selectedAIFNoReportingFlag} options={[{ value: false, label: 'False' }, { value: true, label: 'True' }]} onChange={(selectedObject) => setSelectedAIFNoReportingFlag(selectedObject)} />
                    </OptionWrapper>
                </OptionsGroup>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'Reporting Period Start Date'}
                        tooltip={tooltips.ReportingPeriodStartDate}
                    >
                        <DatePicker className="is-input" dateFormat="yyyy-MM-dd" selected={reportingPeriodStartDate} onChange={(date) => setReportingPeriodStartDate(date)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Reporting Period End Date'}
                        tooltip={tooltips.ReportingPeriodEndDate}
                    >
                        <DatePicker className="is-input" dateFormat="yyyy-MM-dd" selected={reportingPeriodEndDate} onChange={(date) => setReportingPeriodEndDate(date)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Reporting Period Type'}
                        tooltip={tooltips.ReportingPeriodType}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="Reporting Period Type" value={selectedReportPeriod} options={reportPeriodsJson} onChange={(selectedObject) => setSelectedReportPeriod(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Reporting Period Year'}
                        tooltip={tooltips.ReportingPeriodYear}
                    >
                        <DatePicker className="is-input" showYearPicker dateFormat="yyyy" selected={reportingPeriodYear} onChange={(date) => setReportingPeriodYear(date)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Filing Type'}
                        tooltip={tooltips.FilingType}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="Filing Type" value={filingType} options={filingTypesJson} onChange={(selectedObject) => setFilingType(selectedObject)} />
                    </OptionWrapper>
                </OptionsGroup>
            </SectionWrapper>
            <SectionWrapper
                title='Share Class Identifiers'>
                <OptionWrapper
                    fieldName={'Share Class Flag'}
                    tooltip={tooltips.ShareClassFlag}
                >
                    <Select styles={selectStyles} className="is-custom-select" placeholder="Share Class Flag" value={selectedShareClassFlag} options={[{ value: false, label: 'False' }, { value: true, label: 'True' }]} onChange={(selectedObject) => setSelectedShareClassFlag(selectedObject)} />
                </OptionWrapper>
                {selectedShareClassFlag.value && <MultipleElementsWrapper
                    buttonText='Add Share Class'
                    buttonClick={() => handleAddShareClassIdentifier()}>
                    {
                        shareClassIdentifiers.map((shareClassIdentifier, index) => {
                            return (
                                <ComplexOption
                                    key={index}
                                    onDelete={(e, index) => handleDeleteShareClassIdentifier(index)}
                                    title={'Share Class Identifier ' + (index + 1)}
                                    elementIndex={index}>
                                    <OptionWrapper
                                        fieldName={'Share Class National Code'}
                                        tooltip={tooltips.ShareClassNationalCode}
                                    >
                                        <input className="is-input" placeholder="Share Class National Code" value={shareClassIdentifier.shareClassNationalCode} onChange={shareClassNationalCodeChange(index)} />
                                    </OptionWrapper>
                                    <OptionWrapper
                                        fieldName={'Share Class Identifier ISIN'}
                                        tooltip={tooltips.ShareClassIdentifierISIN}
                                    >
                                        <input className="is-input" placeholder="Share Class Identifier ISIN" value={shareClassIdentifier.shareClassIdentifierISIN} onChange={shareClassIdentifierISINChange(index)} />
                                    </OptionWrapper>
                                    <OptionWrapper
                                        fieldName={'Share Class Identifier CUSIP'}
                                        tooltip={tooltips.shareClassIdentifierCUSIP}
                                    >
                                        <input className="is-input" placeholder="Share Class Identifier CUSIP" value={shareClassIdentifier.shareClassIdentifierCUSIP} onChange={shareClassIdentifierCUSIPChange(index)} />
                                    </OptionWrapper>
                                    <OptionWrapper
                                        fieldName={'Share Class Identifier SEDOL'}
                                        tooltip={tooltips.ShareClassIdentifierSEDOL}
                                    >
                                        <input className="is-input" placeholder="Share Class Identifier SEDOL" value={shareClassIdentifier.shareClassIdentifierSEDOL} onChange={shareClassIdentifierSEDOLChange(index)} />
                                    </OptionWrapper>
                                    <OptionWrapper
                                        fieldName={'Share Class Identifier Ticker'}
                                        tooltip={tooltips.ShareClassIdentifierTicker}
                                    >
                                        <input className="is-input" placeholder="Share Class Identifier Ticker" value={shareClassIdentifier.shareClassIdentifierTicker} onChange={shareClassIdentifierTickerChange(index)} />
                                    </OptionWrapper>
                                    <OptionWrapper
                                        fieldName={'Share Class Identifier RIC'}
                                        tooltip={tooltips.ShareClassIdentifierRIC}
                                    >
                                        <input className="is-input" placeholder="Share Class Identifier RIC" value={shareClassIdentifier.shareClassIdentifierRIC} onChange={shareClassIdentifierRICChange(index)} />
                                    </OptionWrapper>
                                    <OptionWrapper
                                        fieldName={'Share Class Name'}
                                        tooltip={tooltips.ShareClassName}
                                    >
                                        <input className="is-input" placeholder="Share Class Name" value={shareClassIdentifier.shareClassName} onChange={shareClassNameChange(index)} />
                                    </OptionWrapper>
                                </ComplexOption>
                            );
                        })
                    }
                </MultipleElementsWrapper>}
            </SectionWrapper>
            <SectionWrapper
                title='AIF Description'>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'AIF Master Feeder Status'}
                        tooltip={tooltips.AIFMasterFeederStatus}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="AIF Master Feeder Status" value={selectedAIFMasterFeederStatus} options={AIFMasterFeederStatusTypesJson} onChange={(selectedObject) => setSelectedAIFMasterFeederStatus(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Predominant AIF Type'}
                        tooltip={tooltips.PredominantAIFType}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="Predominant AIF Type" value={selectedPredominantAIFType} options={predominantAIFTypesJson} onChange={(selectedObject) => setSelectedPredominantAIFType(selectedObject)} />
                    </OptionWrapper>
                </OptionsGroup>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'Base Currency'}
                        tooltip={tooltips.AIFBaseCurrency}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="Base Currency" value={selectedBaseCurrency} options={baseCurrenciesJson} onChange={(selectedObject) => setSelectedBaseCurrency(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'AUM Amount In Base Currency'}
                        tooltip={tooltips.AIFAUMAmountInBaseCurrency}
                    >
                        <input className="is-input" placeholder='AUM Amount In Base Currency' value={aumAmountInBaseCurrency} onChange={(e) => { setAUMAmountInBaseCurrency(e.target.value); }} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'FX EUR Reference Rate Type'}
                        tooltip={tooltips.AIFFXEURReferenceRateType}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="FX EUR Reference Rate Type" value={selectedFXEURReferenceRateType} options={fxEURReferenceRateTypesJson} onChange={(selectedObject) => setSelectedFXEURReferenceRateType(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'FX EUR Rate'}
                        tooltip={tooltips.AIFFXEURRate}
                    >
                        <input className="is-input" placeholder='FX EUR Rate' value={fxEURRate} onChange={(e) => { setFXEURRate(e.target.value); }} />
                    </OptionWrapper>
                    {selectedFXEURReferenceRateType.value === "OTH" && <OptionWrapper
                        fieldName={'FX EUR Other Reference Rate Description'}
                        tooltip={tooltips.FXEUROtherReferenceRateDescription}
                    >
                        <input className="is-input" placeholder='FX EUR Other Reference Rate Description' value={fxEUROtherReferenceRateDescription} onChange={(e) => { setFXEUROtherReferenceRateDescription(e.target.value); }} />
                    </OptionWrapper>}
                </OptionsGroup>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'AIF Net Asset Value'}
                        tooltip={tooltips.AIFNetAssetValue}
                    >
                        <input className="is-input" placeholder='AIF Net Asset Value' value={aifNetAssetValue} onChange={(e) => setAIFNetAssetValue(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'HFT Transaction Number'}
                        tooltip={tooltips.HFTTransactionNumber}
                    >
                        <input className="is-input" placeholder='HFT Transaction Number' value={hftTransactionNumber} onChange={(e) => setHFTTransactionNumber(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'HFT Buy Sell Market Value'}
                        tooltip={tooltips.HFTBuySellMarketValue}
                    >
                        <input className="is-input" placeholder='HFT Buy Sell Market Value' value={hftBuySellMarketValue} onChange={(e) => setHFTBuySellMarketValue(e.target.value)} />
                    </OptionWrapper>
                </OptionsGroup>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'First Funding Source Country'}
                        tooltip={tooltips.FirstFundingSourceCountry}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder={'None'} value={firstFundingSourceCountry} options={countriesISOCodesJson} onChange={(selectedObject) => setFirstFundingSourceCountry(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Second Funding Source Country'}
                        tooltip={tooltips.SecondFundingSourceCountry}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder={'None'} value={secondFundingSourceCountry} options={countriesISOCodesJson} onChange={(selectedObject) => setSecondFundingSourceCountry(selectedObject)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Third Funding Source Country'}
                        tooltip={tooltips.ThirdFundingSourceCountry}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder={'None'} value={thirdFundingSourceCountry} options={countriesISOCodesJson} onChange={(selectedObject) => setThirdFundingSourceCountry(selectedObject)} />
                    </OptionWrapper>
                </OptionsGroup>
            </SectionWrapper>
            <SectionWrapper
                title='Strategies'>
                <MultipleElementsWrapper
                    buttonText='Add Strategy'
                    buttonClick={() => handleAddStrategy()}>
                    {
                        investmentStrategies.map((strategy, index) => {
                            return (
                                <ComplexOption
                                    key={index}
                                    onDelete={(e, index) => handleDeleteStrategy(index)}
                                    title={'Strategy ' + (index + 1)}
                                    elementIndex={index}>
                                    <OptionWrapper
                                        fieldName={'Strategy Type'}
                                        tooltip={tooltips.StrategyType}
                                    >
                                        <Select styles={selectStyles} className="is-custom-select" placeholder='Strategy Type' value={strategy.strategyType} options={strategyTypesToSelect} onChange={strategyTypeChange(index)} />
                                    </OptionWrapper>
                                    <OptionWrapper
                                        fieldName={'Primary Strategy Flag'}
                                        tooltip={tooltips.PrimaryStrategyFlag}
                                    >
                                        <Select styles={selectStyles} className="is-custom-select" placeholder='Primary Strategy Flag' value={strategy.isPrimary} options={[{ value: true, label: 'True' }, { value: false, label: 'False' }]} onChange={strategyIsPrimaryChange(index)} />
                                    </OptionWrapper>
                                    <OptionWrapper
                                        fieldName={'Strategy NAV Rate'}
                                        tooltip={tooltips.StrategyNAVRate}
                                    >
                                        <input className="is-input" placeholder="Strategy NAV Rate" value={strategy.navRate} onChange={strategyNavRateChange(index)} />
                                    </OptionWrapper>
                                </ComplexOption>
                            );
                        })
                    }
                </MultipleElementsWrapper>
            </SectionWrapper>
            <SectionWrapper
                title='Main Instruments Traded'
                tooltip={tooltips.top5InstrumentsTraded}>
                <MultipleElementsWrapper>
                    {
                        mainInstrumentsTraded.map((instrument, index) => {
                            const subAssetTypeValue = instrument.subAssetType === undefined ? 'NTA_NTA_NOTA' : instrument.subAssetType.value;
                            return (
                                <ComplexOption
                                    title={'Ranking ' + instrument.ranking}
                                    tooltip={tooltips.rankingInstrumentsTraded}
                                    elementIndex={index}>
                                    <OptionWrapper
                                        fieldName={'Sub-Asset Type'}
                                        tooltip={tooltips.subAssetTypeMainTradedInstruments}
                                    >
                                        <Select styles={selectStyles} className="is-custom-select" placeholder='--Wybierz wartość--' value={instrument.subAssetType} options={subAssetsJson} onChange={instrumentSubAssetTypeChange(index)} />
                                    </OptionWrapper>
                                    {subAssetTypeValue !== 'NTA_NTA_NOTA' && <OptionWrapper
                                        fieldName={'Position Value'}
                                        tooltip={tooltips.positionValue}
                                    >
                                        <input className="is-input" placeholder="Position Value" value={instrument.positionValue} onChange={instrumentPositionValueChange(index)} />
                                    </OptionWrapper>}
                                </ComplexOption>
                            );
                        })
                    }
                </MultipleElementsWrapper>
            </SectionWrapper>
            <SectionWrapper
                title='Geographical Focus'>
                <h5><BiggerTooltip title={tooltips.navGeographicalFocus}><span>NAV Geographical Focus</span></BiggerTooltip></h5>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'Africa NAV Rate'}
                        tooltip={tooltips.AfricaNAVRate}
                    >
                        <input className="is-input" placeholder='Africa NAV Rate' value={africaNAVRate} onChange={(e) => setAfricaNAVRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Asia Pacific NAV Rate'}
                        tooltip={tooltips.AsiaPacificNAVRate}
                    >
                        <input className="is-input" placeholder='Asia Pacific NAV Rate' value={asiaPacificNAVRate} onChange={(e) => setAsiaPacificNAVRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Europe NAV Rate'}
                        tooltip={tooltips.EuropeNAVRate}
                    >
                        <input className="is-input" placeholder='Europe NAV Rate' value={europeNAVRate} onChange={(e) => setEuropeNAVRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'EEA NAV Rate'}
                        tooltip={tooltips.EEANAVRate}
                    >
                        <input className="is-input" placeholder='EEA NAV Rate' value={eEANAVRate} onChange={(e) => setEEANAVRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Middle East NAV Rate'}
                        tooltip={tooltips.MiddleEastNAVRate}
                    >
                        <input className="is-input" placeholder='Middle East NAV Rate' value={middleEastNAVRate} onChange={(e) => setMiddleEastNAVRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'North America NAV Rate'}
                        tooltip={tooltips.NorthAmericaNAVRate}
                    >
                        <input className="is-input" placeholder='North America NAV Rate' value={northAmericaNAVRate} onChange={(e) => setNorthAmericaNAVRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'South America NAV Rate'}
                        tooltip={tooltips.SouthAmericaNAVRate}
                    >
                        <input className="is-input" placeholder='South America NAV Rate' value={southAmericaNAVRate} onChange={(e) => setSouthAmericaNAVRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Supra National NAV Rate'}
                        tooltip={tooltips.SupraNationalNAVRate}
                    >
                        <input className="is-input" placeholder='Supra National NAV Rate' value={supraNationalNAVRate} onChange={(e) => setSupraNationalNAVRate(e.target.value)} />
                    </OptionWrapper>
                </OptionsGroup>
                <h5><BiggerTooltip title={tooltips.aumGeographicalFocus}><span>AUM Geographical Focus</span></BiggerTooltip></h5>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'Africa AUM Rate'}
                        tooltip={tooltips.AfricaAUMRate}
                    >
                        <input className="is-input" placeholder='Africa AUM Rate' value={africaAUMRate} onChange={(e) => setAfricaAUMRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Asia Pacific AUM Rate'}
                        tooltip={tooltips.AsiaPacificAUMRate}
                    >
                        <input className="is-input" placeholder='Asia Pacific AUM Rate' value={asiaPacificAUMRate} onChange={(e) => setAsiaPacificAUMRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Europe AUM Rate'}
                        tooltip={tooltips.EuropeAUMRate}
                    >
                        <input className="is-input" placeholder='Europe AUM Rate' value={europeAUMRate} onChange={(e) => setEuropeAUMRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'EEA AUM Rate'}
                        tooltip={tooltips.EEAAUMRate}
                    >
                        <input className="is-input" placeholder='EEA AUM Rate' value={eEAAUMRate} onChange={(e) => setEEAAUMRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Middle East AUM Rate'}
                        tooltip={tooltips.MiddleEastAUMRate}
                    >
                        <input className="is-input" placeholder='Middle East AUM Rate' value={middleEastAUMRate} onChange={(e) => setMiddleEastAUMRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'North America AUM Rate'}
                        tooltip={tooltips.NorthAmericaAUMRate}
                    >
                        <input className="is-input" placeholder='North America AUM Rate' value={northAmericaAUMRate} onChange={(e) => setNorthAmericaAUMRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'South America AUM Rate'}
                        tooltip={tooltips.SouthAmericaAUMRate}
                    >
                        <input className="is-input" placeholder='South America AUM Rate' value={southAmericaAUMRate} onChange={(e) => setSouthAmericaAUMRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Supra National AUM Rate'}
                        tooltip={tooltips.SupraNationalAUMRate}
                    >
                        <input className="is-input" placeholder='Supra National AUM Rate' value={supraNationalAUMRate} onChange={(e) => setSupraNationalAUMRate(e.target.value)} />
                    </OptionWrapper>
                </OptionsGroup>
            </SectionWrapper>
            <SectionWrapper
                title='Principal Exposures'>
                <h4><BiggerTooltip title={tooltips.top10PrincipalExposures}><span>Top 10 Principal Exposures</span></BiggerTooltip></h4>
                <MultipleElementsWrapper>
                    {
                        principalExposures.map((exposure, index) => {
                            return (
                                <ComplexOption
                                    title={'Ranking ' + exposure.ranking}
                                    tooltip={tooltips.rankingPrincipalExposures}
                                    elementIndex={index}>
                                    <OptionWrapper
                                        fieldName={'Asset Macro Type'}
                                        tooltip={tooltips.assetMacroTypePrincipalExposure}
                                    >
                                        <Select styles={selectStyles} className="is-custom-select" placeholder='--Wybierz wartość--' value={exposure.assetMacroType} options={assetMacroTypesJson} onChange={exposureAssetMacroTypeChange(index)} />
                                    </OptionWrapper>
                                    {exposure.assetMacroType.value !== 'NTA' && <OptionWrapper
                                        fieldName={'Sub-Asset Type'}
                                        tooltip={tooltips.subAssetTypePrincipalExposure}
                                    >
                                        <Select styles={selectStyles} className="is-custom-select" placeholder='Sub-Asset Type' value={exposure.subAssetType} options={subAssetsJson.filter((e) => { return e.value.includes(exposure.assetMacroType.value) })} onChange={exposureSubAssetTypeChange(index)} />
                                    </OptionWrapper>}
                                    {exposure.assetMacroType.value !== 'NTA' && <OptionWrapper
                                        fieldName={'Position Type'}
                                        tooltip={tooltips.positionType}
                                    >
                                        <Select styles={selectStyles} className="is-custom-select" placeholder='Position Type' value={exposure.positionType} options={positionTypesJson} onChange={exposurePositionTypeChange(index)} />
                                    </OptionWrapper>}
                                    {exposure.assetMacroType.value !== 'NTA' && <OptionWrapper
                                        fieldName={'Aggregated Value Amount'}
                                        tooltip={tooltips.aggregatedValueAmountExposure}
                                    >
                                        <input className="is-input" placeholder="Aggregated Value Amount" value={exposure.aggregatedValueAmount} onChange={exposureValueAmountChange(index)} />
                                    </OptionWrapper>}
                                    {exposure.assetMacroType.value !== 'NTA' && <OptionWrapper
                                        fieldName={'Aggregated Value Rate'}
                                        tooltip={tooltips.aggregatedValueRateExposure}
                                    >
                                        <input className="is-input" placeholder="Aggregated Value Rate" value={exposure.aggregatedValueRate} onChange={exposureValueRateChange(index)} />
                                    </OptionWrapper>}
                                    {exposure.assetMacroType.value !== 'NTA' && <OptionWrapper
                                        fieldName={'Counterparty Entity Name'}
                                        tooltip={tooltips.counterPartyEntityNameOptional}
                                    >
                                        <input className="is-input" placeholder="Counterparty Entity Name" value={exposure.counterpartyEntityName} onChange={exposureConterpartyNameChange(index)} />
                                    </OptionWrapper>}
                                    {exposure.assetMacroType.value !== 'NTA' && <OptionWrapper
                                        fieldName={'Counterparty Entity BIC Code'}
                                        tooltip={tooltips.counterPartyEntityBICCodeOptional}
                                    >
                                        <input className="is-input" placeholder="Counterparty Entity BIC Code" value={exposure.counterpartyEntityBIC} onChange={exposureConterpartyBICChange(index)} />
                                    </OptionWrapper>}
                                    {exposure.assetMacroType.value !== 'NTA' && <OptionWrapper
                                        fieldName={'Counterparty Entity LEI Code'}
                                        tooltip={tooltips.counterPartyEntityLEICodeOptional}
                                    >
                                        <input className="is-input" placeholder="Counterparty Entity LEI Code" value={exposure.counterpartyEntityLEI} onChange={exposureConterpartyLEIChange(index)} />
                                    </OptionWrapper>}
                                </ComplexOption>
                            );
                        })
                    }
                </MultipleElementsWrapper>
            </SectionWrapper>
            <SectionWrapper
                title='Most Important Concentration' >
                <h5><BiggerTooltip title={tooltips.top5PortfolioConcentrations}><span>Top 5 Portfolio Concentrations</span></BiggerTooltip></h5>
                <MultipleElementsWrapper>
                    {
                        portfolioConcentrations.map((portfolioConcentration, index) => {
                            const assetType = portfolioConcentration.assetType === undefined ? 'NTA_NTA' : portfolioConcentration.assetType.value;
                            return (
                                <ComplexOption
                                    title={'Ranking ' + portfolioConcentration.ranking}
                                    tooltip={tooltips.rankingPortfolioConcentration}
                                    elementIndex={index}>
                                    <OptionWrapper
                                        fieldName={'Asset Type'}
                                        tooltip={tooltips.assetTypePortfolioConcentrations}
                                    >
                                        <Select styles={selectStyles} className="is-custom-select" placeholder='Asset Type' value={portfolioConcentration.assetType} options={assetTypesJson} onChange={portfolioConcentrationAssetTypeChange(index)} />
                                    </OptionWrapper>
                                    {assetType !== 'NTA_NTA' && <OptionWrapper
                                        fieldName={'Position Type'}
                                        tooltip={tooltips.positionType}
                                    >
                                        <Select styles={selectStyles} className="is-custom-select" placeholder='Position Type' value={portfolioConcentration.positionType} options={positionTypesJson} onChange={portfolioConcentrationPositionTypeChange(index)} />
                                    </OptionWrapper>}
                                    {assetType !== 'NTA_NTA' && <OptionWrapper
                                        fieldName={'Market Code Type'}
                                        tooltip={tooltips.marketCodeType}
                                    >
                                        <Select styles={selectStyles} className="is-custom-select" placeholder='Market Type Code' value={portfolioConcentration.marketType} options={marketTypesJson} onChange={portfolioConcentrationMarketTypeChange(index)} />
                                    </OptionWrapper>}
                                    {assetType !== 'NTA_NTA' && portfolioConcentration.marketType.value === 'MIC' && <OptionWrapper
                                        fieldName={'Market Code'}
                                        tooltip={tooltips.marketCode}
                                    >
                                        <input className="is-input" placeholder="Market Code" value={portfolioConcentration.marketCode} onChange={portfolioConcentrationMarketCodeChange(index)} />
                                    </OptionWrapper>}
                                    {assetType !== 'NTA_NTA' && <OptionWrapper
                                        fieldName={'Aggregated Value Amount'}
                                        tooltip={tooltips.aggregatedValueAmountPortfolio}
                                    >
                                        <input className="is-input" placeholder="Aggregated Value Amount" value={portfolioConcentration.aggregatedValueAmount} onChange={portfolioConcentrationValueAmountChange(index)} />
                                    </OptionWrapper>}
                                    {assetType !== 'NTA_NTA' && <OptionWrapper
                                        fieldName={'Aggregated Value Rate'}
                                        tooltip={tooltips.aggregatedValueRatePortfolio}
                                    >
                                        <input className="is-input" placeholder="Aggregated Value Rate" value={portfolioConcentration.aggregatedValueRate} onChange={portfolioConcentrationValueRateChange(index)} />
                                    </OptionWrapper>}
                                    {assetType !== 'NTA_NTA' && portfolioConcentration.marketType.value === 'OTC' && <OptionWrapper
                                        fieldName={'Counterparty Entity Name'}
                                        tooltip={tooltips.counterPartyEntityName}
                                    >
                                        <input className="is-input" placeholder="Counterparty Entity Name" value={portfolioConcentration.counterpartyEntityName} onChange={portfolioConcentrationConterpartyNameChange(index)} />
                                    </OptionWrapper>}
                                    {assetType !== 'NTA_NTA' && portfolioConcentration.marketType.value === 'OTC' && <OptionWrapper
                                        fieldName={'Counterparty Entity BIC Code'}
                                        tooltip={tooltips.counterPartyEntityBICCode}
                                    >
                                        <input className="is-input" placeholder="Counterparty Entity BIC Code" value={portfolioConcentration.counterpartyEntityBIC} onChange={portfolioConcentrationConterpartyBICChange(index)} />
                                    </OptionWrapper>}
                                    {assetType !== 'NTA_NTA' && portfolioConcentration.marketType.value === 'OTC' && <OptionWrapper
                                        fieldName={'Counterparty Entity LEI Code'}
                                        tooltip={tooltips.counterPartyEntityLEICode}
                                    >
                                        <input className="is-input" placeholder="Counterparty Entity LEI Code" value={portfolioConcentration.counterpartyEntityLEI} onChange={portfolioConcentrationConterpartyLEIChange(index)} />
                                    </OptionWrapper>}
                                </ComplexOption>
                            );
                        })
                    }
                </MultipleElementsWrapper>
                {selectedPredominantAIFType.value === 'PEQF' && <OptionsGroup>
                    <OptionWrapper
                        fieldName={'Typical Position Size'}
                        tooltip={tooltips.typicalPosiztionSize}
                    >
                        <Select styles={selectStyles} className="is-custom-select" placeholder="Typical Position Size" value={selectedTypicalPositionSize} options={positionSizesJson} onChange={(selectedObject) => setSelectedTypicalPositionSize(selectedObject)} />
                    </OptionWrapper>
                </OptionsGroup>}
                <h4><BiggerTooltip title={tooltips.top3PrinicipalMarkets}><span>Top 3 Principal Markets</span></BiggerTooltip></h4>
                <MultipleElementsWrapper>
                    {
                        aifPrincipalMarkets.map((market, index) => {
                            return (
                                <ComplexOption
                                    title={'Ranking ' + market.ranking}
                                    tooltip={tooltips.rankingPrincipalMarket}
                                    elementIndex={index}>
                                    <OptionWrapper
                                        fieldName={'Market Code Type'}
                                        tooltip={tooltips.marketCodeType}
                                    >
                                        <Select styles={selectStyles} className="is-custom-select" placeholder='Market Type Code' value={market.marketType} options={marketTypesJson} onChange={marketTypeAIFChange(index)} />
                                    </OptionWrapper>
                                    {market.marketType.value === 'MIC' && <OptionWrapper
                                        fieldName={'Market Code'}
                                        tooltip={tooltips.marketCode}
                                    >
                                        <input className="is-input" placeholder="Market Code" value={market.marketCode} onChange={marketCodeAIFChange(index)} />
                                    </OptionWrapper>}
                                    {market.marketType.value !== 'NOT' && <OptionWrapper
                                        fieldName={'Aggregated Value Amount'}
                                        tooltip={tooltips.aggregatedValueAmount}
                                    >
                                        <input className="is-input" placeholder="Aggregated Value Amount" value={market.aggregatedValueAmount} onChange={marketAmountAIFChange(index)} />
                                    </OptionWrapper>}
                                </ComplexOption>
                            );
                        }, this)
                    }
                </MultipleElementsWrapper>
                <h4><BiggerTooltip title={tooltips.investorConcentration}><span>Investor Concentration</span></BiggerTooltip></h4>
                <OptionsGroup>
                    <OptionWrapper
                        fieldName={'Main Beneficial Owners Rate'}
                        tooltip={tooltips.mainBeneficialOwnersRate}
                    >
                        <input className="is-input" placeholder='Main Beneficial Owners Rate' value={mainBeneficialOwnersRate} onChange={(e) => setMainBeneficialOwnersRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Professional Investor Concentration Rate'}
                        tooltip={tooltips.professionalInvestorConcentrationRate}
                    >
                        <input className="is-input" placeholder='Professional Investor Concentration Rate' value={professionalInvestorConcentrationRate} onChange={(e) => setProfessionalInvestorConcentrationRate(e.target.value)} />
                    </OptionWrapper>
                    <OptionWrapper
                        fieldName={'Retail Investor Concentration Rate'}
                        tooltip={tooltips.retailInvestorConcentrationRate}
                    >
                        <input className="is-input" placeholder='Retail Investor Concentration Rate' value={retailInvestorConcentrationRate} onChange={(e) => setRetailInvestorConcentrationRate(e.target.value)} />
                    </OptionWrapper>
                </OptionsGroup>
            </SectionWrapper>
            {
                (selectedAIFContentType.value === "2" || selectedAIFContentType.value === "4") && <SectionWrapper
                    title='AIF Individual Info - 24(2)' >
                    <h2><BiggerTooltip title={tooltips.riskProfile}><span>Risk Profile</span></BiggerTooltip></h2>
                    <h4><span>Market Risks</span></h4>
                    <OptionsGroup>
                        <OptionWrapper
                            fieldName={'Annual Investment Return Rate'}
                            tooltip={tooltips.annualInvestmentReturnRate}
                        >
                            <input className="is-input" placeholder='Annual Investment Return Rate' value={annualInvestmentReturnRate} onChange={(e) => setAnnualInvestmentReturnRate(e.target.value)} />
                        </OptionWrapper>
                    </OptionsGroup>
                    <h4><span>Market Risk Measures</span></h4>
                    <MultipleElementsWrapper
                        buttonText='Add Market Risk Measure'
                        buttonClick={() => handleAddMarketRiskMeasure()}>
                        {
                            marketRiskMeasures.map((marketRiskMeasure, index) => {
                                const riskMeasureType = marketRiskMeasure.riskMeasureType === undefined ? 'NTA_NTA' : marketRiskMeasure.riskMeasureType.value;
                                return (
                                    <ComplexOption
                                        key={index}
                                        onDelete={(e, index) => handleDeleteMarketRiskMeasure(index)}
                                        title={'Market Risk Measure ' + (index + 1)}
                                        elementIndex={index}>
                                        <OptionWrapper
                                            fieldName={'Risk Measure Type'}
                                            tooltip={tooltips.riskMeasureType}
                                        >
                                            <Select styles={selectStyles} className="is-custom-select" placeholder='Risk Measure Type' value={marketRiskMeasure.riskMeasureType} options={marketRiskMeasuresToSelect} onChange={marketRiskMeasureTypeChange(index)} />
                                        </OptionWrapper>
                                        {(riskMeasureType === "NET_EQTY_DELTA" || riskMeasureType === "NET_FX_DELTA" || riskMeasureType === "NET_CTY_DELTA") && <OptionWrapper
                                            fieldName={'Risk Measure Value'}
                                            tooltip={tooltips.riskMeasureValue}
                                        >
                                            <input className="is-input" placeholder="Risk Measure Value" value={marketRiskMeasure.riskMeasureValue} onChange={marketRiskMeasureValueChange(index)} />
                                        </OptionWrapper>}
                                        {(riskMeasureType === "NET_DV01" || riskMeasureType === "NET_CS01") && <div>
                                            <OptionWrapper
                                                fieldName={'Less Five Years Risk Measure Value'}
                                                tooltip={tooltips.noTooltip}
                                            >
                                                <input className="is-input" placeholder="Less Five Years Risk Measure Value" value={marketRiskMeasure.lessFiveYearsRiskMeasureValue} onChange={lessFiveYearsRiskMeasureValueChange(index)} />
                                            </OptionWrapper>
                                            <OptionWrapper
                                                fieldName={'Fifthteen Years Risk Measure Value'}
                                                tooltip={tooltips.noTooltip}
                                            >
                                                <input className="is-input" placeholder="Fifthteen Years Risk Measure Value" value={marketRiskMeasure.fifthteenYearsRiskMeasureValue} onChange={fifthteenYearsRiskMeasureValueChange(index)} />
                                            </OptionWrapper>
                                            <OptionWrapper
                                                fieldName={'More Fifthteen Years Risk Measure Value'}
                                                tooltip={tooltips.noTooltip}
                                            >
                                                <input className="is-input" placeholder="More Fifthteen Years Risk Measure Value" value={marketRiskMeasure.moreFifthteenYearsRiskMeasureValue} onChange={moreFifthteenYearsRiskMeasureValueChange(index)} />
                                            </OptionWrapper>
                                        </div>}
                                        {riskMeasureType === "VEGA_EXPO" && <div>
                                            <OptionWrapper
                                                fieldName={'Current Market Risk Measure Value'}
                                                tooltip={tooltips.noTooltip}
                                            >
                                                <input className="is-input" placeholder="Current Market Risk Measure Value" value={marketRiskMeasure.currentMarketRiskMeasureValue} onChange={currentMarketRiskMeasureValueChange(index)} />
                                            </OptionWrapper>
                                            <OptionWrapper
                                                fieldName={'Lower Market Risk Measure Value'}
                                                tooltip={tooltips.noTooltip}
                                            >
                                                <input className="is-input" placeholder="Lower Market Risk Measure Value" value={marketRiskMeasure.lowerMarketRiskMeasureValue} onChange={lowerMarketRiskMeasureValueChange(index)} />
                                            </OptionWrapper>
                                            <OptionWrapper
                                                fieldName={'Higher Market Risk Measure Value'}
                                                tooltip={tooltips.noTooltip}
                                            >
                                                <input className="is-input" placeholder="Higher Market Risk Measure Value" value={marketRiskMeasure.higherMarketRiskMeasureValue} onChange={higherMarketRiskMeasureValueChange(index)} />
                                            </OptionWrapper>
                                        </div>}
                                        {riskMeasureType === "VAR" && <div>
                                            <OptionWrapper
                                                fieldName={'VAR Value'}
                                                tooltip={tooltips.noTooltip}
                                            >
                                                <input className="is-input" placeholder="VAR Value" value={marketRiskMeasure.varValue} onChange={varValueChange(index)} />
                                            </OptionWrapper>
                                            <OptionWrapper
                                                fieldName={'VAR Calculation Method Code Type'}
                                                tooltip={tooltips.noTooltip}
                                            >
                                                <input className="is-input" placeholder="VAR Calculation Method Code Type" value={marketRiskMeasure.varCalculationMethodCodeType} onChange={varCalculationMethodCodeTypeChange(index)} />
                                            </OptionWrapper>
                                        </div>}
                                        <OptionWrapper
                                            fieldName={'Risk Measure Description'}
                                            tooltip={tooltips.riskMeasureDescription}
                                        >
                                            <input className="is-input" placeholder="Risk Measure Description" value={marketRiskMeasure.riskMeasureDescription} onChange={marketRiskMeasureDescriptionChange(index)} />
                                        </OptionWrapper>
                                    </ComplexOption>
                                );
                            })
                        }
                    </MultipleElementsWrapper>
                    <h4><span>Counterparty Risk Profile</span></h4>
                    <h2><BiggerTooltip title={tooltips.stressTests}><span>Stress Tests</span></BiggerTooltip></h2>
                    <OptionsGroup>
                        <OptionWrapper
                            fieldName={'Stress Tests Result Article 15'}
                            tooltip={tooltips.stressTestsResultArticle15}
                        >
                            <textarea className="is-input" placeholder='Stress Tests Result Article 15' value={stressTestsResultArticle15} onChange={(e) => setStressTestsResultArticle15(e.target.value)} />
                        </OptionWrapper>
                        <OptionWrapper
                            fieldName={'Stress Tests Result Article 16'}
                            tooltip={tooltips.stressTestsResultArticle16}
                        >
                            <textarea className="is-input" placeholder='Stress Tests Result Article 16' value={stressTestsResultArticle16} onChange={(e) => setStressTestsResultArticle16(e.target.value)} />
                        </OptionWrapper>
                    </OptionsGroup>
                </SectionWrapper>
            }
            {
                (selectedAIFContentType.value === "4" || selectedAIFContentType.value === "5") && <div>
                    <h1>AIF Leverage Info - 24(4)</h1>
                </div>
            }
            <SectionWrapper>
                <Button onClick={() => handleCreateXMLClick()}>Generuj XML</Button>
            </SectionWrapper>
        </PaneContent >
    )
}