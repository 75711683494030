import styles from './PageSection.module.css';

interface PageSectionProps {
    children: React.ReactNode,
    flush?: boolean,
    spaceAdaptive?: boolean,
    full?: boolean,
    backgroundImagePath?: string,
    backgroundColor?: string
}

export const PageSection = ({ children, flush, spaceAdaptive, full, backgroundImagePath, backgroundColor = '#010e1e' }: PageSectionProps) => {
    const setCSS = () => {
        const cssClasses = [styles['pane'], styles['pane--adaptiveHg']];

        if (flush) {
            cssClasses.push(styles['pane--flush']);
        }

        if (full) {
            cssClasses.push(styles['pane--full']);
        }

        if (spaceAdaptive) {
            cssClasses.push(styles['pane--adaptiveSpace']);
        }

        return cssClasses.join(' ');
    };

    if (full) {
        return (
            <section className={setCSS()}>
                {children}
            </section>
        )
    }

    if (backgroundImagePath !== undefined) {
        return (
            <section className={[styles['pane'], styles['pane--full'], styles['pane--backgroundTop']].join(' ')} style={{ backgroundColor: backgroundColor }}>
                <BackgroundContainer>
                    <div className={styles['pane-background']} style={{ backgroundImage: `url(${backgroundImagePath})` }}></div>
                </BackgroundContainer>
                <PaneContent>
                    {children}
                </PaneContent>
            </section>
        );
    }

    return (
        <section className={setCSS()}>
            <PaneContent>
                {children}
            </PaneContent>
        </section>
    );
}

interface BackgroundContainerProps {
    children: React.ReactNode
}

export const BackgroundContainer = ({ children }: BackgroundContainerProps) => {
    return (
        <div className={styles['pane-backgroundContainer']}>{children}</div>
    );
}

interface PaneContentProps {
    children: React.ReactNode
}

export const PaneContent = ({ children }: PaneContentProps) => {
    return (
        <div className={styles['pane-content']}>{children}</div>
    );
}

interface SlidePaneProps {
    children: React.ReactNode
}

export const SlidePane = ({ children }: SlidePaneProps) => {
    return (
        <section className={[styles['pane'], styles['pane--full'], styles['pane--adaptiveHg'], styles['home-slidePane']].join(' ')}>{children}</section>
    );
}