import { useEffect } from 'react';
import data from '../../assets/about.json';
import styles from './About.module.css';

export default function AboutPage() {

    useEffect(() => {
        document.title = 'Czym jest generator?';
    }, []);

    return (
        <div className={styles['single-page-container']}>
            <section className={styles['section']}>
                <div className={styles['section-title']}>{data.title}</div>
                <ul className={styles['list']}>
                    {
                        data.about.map((item, index) => {
                            return (
                                <li key={index} className={styles['list-element-vertical']}>
                                    <span className={styles['faq-list-element-question']}>{item.bulletPoint}</span>
                                    {
                                        item.pointsList.map((listItem, itemIndex) => {
                                            return (
                                                <span key={`${index}_${itemIndex}`} className={styles['faq-list-element-answer']}>{listItem}</span>
                                            );
                                        })
                                    }
                                </li>
                            );
                        })
                    }
                </ul>
            </section>
        </div>
    );
}