import styles from './OptionsGroup.module.css';

interface OptionsGroupProps {
    children: React.ReactNode
}

const OptionsGroup = (props: OptionsGroupProps) => {

    const { children } = props;

    return (
        <div className={styles['wrapper']}>
            {children}
        </div>
    );
}

export default OptionsGroup;