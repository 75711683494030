import { Puff } from 'react-loader-spinner';
import styles from './Loading.module.css';

interface LoadingProps {
    show: boolean
    small?: boolean
    message?: string
}

export default function Loading(props: LoadingProps) {

    const {show, small, message} = props;

    return (
        <div className={styles['loading-container']}>
            {show && <Puff color="#00BFFF" height={small ? 25 : 100} width={small ? 25 : 100} />}
            <div className={small ? styles['small-loading-message'] : styles['loading-message']}>{message ? 'Trwa ładowanie' : message}</div>
        </div>
    );
}