import { Button } from '../button/Button';
import styles from './NavBar.module.css';

interface NavBarProps {
    sectionName: string
    buttonName: string
    onButtonClick: () => void
}

const NavBar = (props: NavBarProps) => {

    const { buttonName, sectionName, onButtonClick } = props;

    return (
        <div className={styles['nav-bar']}>
            <div className={styles['left-side-wrapper']}>
                <Button onClick={onButtonClick}>{buttonName}</Button>
                <span>{sectionName}</span>
            </div>
            <span>v. 1.0.1a</span>
        </div>
    )
}

export default NavBar;