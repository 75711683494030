import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BiggerTooltip from '../tooltip/Tooltip';
import styles from './OptionWrapper.module.css';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface OptionWrapperProps {
    children: React.ReactNode
    tooltip: string
    fieldName: string
}

const OptionWrapper = (props: OptionWrapperProps) => {

    const { tooltip, fieldName, children } = props;

    return (
        <div className={styles['wrapper']}>
            <div className={styles['title']}>
                <span>{fieldName}</span>
                <BiggerTooltip title={tooltip}>
                    <i><FontAwesomeIcon icon={faInfoCircle} /></i>
                </BiggerTooltip>
            </div>
            {children}
        </div>
    );
}

export default OptionWrapper;