import styles from './Button.module.css';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: string,
    active?: boolean
}

export const Button = ({ color, active = false, ...buttonProps }: ButtonProps) => {

    return (
        <button
            className={[styles['button'], active ? styles['active'] : null].join(' ')}
            style={color ? { backgroundColor: color } : {}}
            {...buttonProps}
        >{buttonProps.children}</button>
    );
}

export const SmallButton = ({ color, active, ...buttonProps }: ButtonProps) => {
    return (
        <button
            className={[styles['button'], styles['button--small'], active ? styles['active'] : null].join(' ')}
            style={color ? { backgroundColor: color } : {}}
            {...buttonProps}
        >{buttonProps.children}</button>
    );
}

export const VerySmallButton = ({ color, active, ...buttonProps }: ButtonProps) => {
    return (
        <button
            className={[styles['button'], styles['button--very-small'], active ? styles['active'] : null].join(' ')}
            style={color ? { backgroundColor: color } : {}}
            {...buttonProps}
        >{buttonProps.children}</button>
    );
}

export const FullButton = ({ color, active, ...buttonProps }: ButtonProps) => {
    return (
        <button
            className={[styles['button'], styles['button--full'], active ? styles['active'] : null].join(' ')}
            style={color ? { backgroundColor: color } : {}}
            {...buttonProps}
        >{buttonProps.children}</button>
    );
}