import { useState, useEffect, ChangeEvent } from 'react';
import { create } from 'xmlbuilder2';
import tooltips from '../../assets/tooltips.json';
import AIFCreator from '../xmlCreators/AIFCreator';
import AIFMCreator from '../xmlCreators/AIFMCreator';
import AIFCancel from '../xmlCreators/AIFCancel';
import AIFMCancel from '../xmlCreators/AIFMCancel';
import Loading from '../loader/Loading';
import styles from './MainPage.module.css';
import AboutPage from '../about/AboutPage';
import BiggerTooltip from '../tooltip/Tooltip';
import { Button } from '../button/Button';
import { Divider } from '../divider/Divider';
import { PaneContent } from '../pageSecion/PageSection';
import NavBar from '../navBar/NavBar';
import { PageContainer, PageLoaderContainer } from '../pageContainer/PageContainer';
import { SpaceAdaptiveMedium, SpaceAdaptiveSmall } from '../spaceAdaptive/SpaceAdaptive';

export default function MainPage() {
    const [selectedPage, setSelectedPage] = useState<'MAIN' | 'UPLOADING' | 'ABOUT' | 'DATAIF' | 'DATAIF_CANCEL' | 'DATMAN' | 'DATMAN_CANCEL'>('MAIN');
    const [uploadedFile, setUploadedFile] = useState(null);

    const uploadXML = (event: ChangeEvent<HTMLInputElement>) => {

        if (!event.target.files) {
            alert('No file detected.');
            setSelectedPage('MAIN');
            return;
        }

        const file = event.target.files[0];
        const reader = new FileReader();
        setSelectedPage('UPLOADING');
        reader.addEventListener('loadend', (event) => {
            const xmlFileContent = event.target?.result;
            if (!xmlFileContent) {
                alert('File is empty');
                setSelectedPage('MAIN');
                return;
            }
            const doc = create(xmlFileContent);
            const obj = doc.end({ format: 'object' }) as any;

            if (obj.AIFReportingInfo !== undefined) {
                if (obj.AIFReportingInfo.AIFRecordInfo !== undefined) {
                    setUploadedFile(obj);
                    setSelectedPage('DATAIF')
                } else if (obj.AIFReportingInfo.CancellationAIFRecordInfo !== undefined) {
                    setUploadedFile(obj);
                    setSelectedPage('DATAIF_CANCEL');
                } else {
                    alert('Wrong file content format. Check your XML schema.');
                    setSelectedPage('MAIN')
                }
            } else if (obj.AIFMReportingInfo !== undefined) {
                if (obj.AIFMReportingInfo.AIFMRecordInfo !== undefined) {
                    setUploadedFile(obj);
                    setSelectedPage('DATMAN');
                } else if (obj.AIFMReportingInfo.CancellationAIFMRecordInfo !== undefined) {
                    setUploadedFile(obj);
                    setSelectedPage('DATMAN_CANCEL');
                } else {
                    alert('Wrong file content format. Check your XML schema.');
                    setSelectedPage('MAIN')
                }
            } else {
                alert('Wrong file content format. Check your XML schema.');
                setSelectedPage('MAIN')
            }
        });
        reader.readAsText(file);
    }

    useEffect(() => {
        document.title = 'Generator raportów ESMA';
    }, []);

    if (selectedPage === 'MAIN') {
        document.title = 'Generator raportów ESMA';
        return (
            <>
                <NavBar sectionName='MAIN' buttonName='O generatorze' onButtonClick={() => setSelectedPage('ABOUT')} />
                <PageContainer>
                    <PaneContent>
                        <div className={styles['options-wrapper']}>
                            <div className={styles['options-element']}>
                                <div className={styles['label']}>Utwórz nowy raport</div>
                            </div>
                        </div>
                        <div className={styles['options-wrapper']}>
                            <div className={styles['options-element']}>
                                <Button onClick={() => setSelectedPage('DATMAN')}>DATMAN</Button>
                            </div>
                            <div className={styles['options-element']}>
                                <Button onClick={() => setSelectedPage('DATAIF')}>DATAIF</Button>
                            </div>
                        </div>
                        <div className={styles['options-wrapper']}>
                            <div className={styles['options-element']}>
                                <div className={styles['label']}>Anulowanie raportu</div>
                            </div>
                        </div>
                        <div className={styles['options-wrapper']}>
                            <div className={styles['options-element']}>
                                <Button onClick={() => setSelectedPage('DATMAN_CANCEL')}>DATMAN</Button>
                            </div>
                            <div className={styles['options-element']}>
                                <Button onClick={() => setSelectedPage('DATAIF_CANCEL')}>DATAIF</Button>
                            </div>
                        </div>
                    </PaneContent>
                    <Divider />
                    <PaneContent>
                        <SpaceAdaptiveMedium />
                        <BiggerTooltip title={tooltips.uploadReport}>
                            <div className={styles['options-wrapper']}>
                                <div className={styles['options-wrapper']}>
                                    <div className={styles['options-element']}>
                                        <div className={styles['label']}>Lub załaduj istniejący z pliku</div>
                                    </div>
                                </div>
                                <div className={styles['options-wrapper']}>
                                    <div className={styles['options-element']}>
                                        <input type="file" accept=".xml" onChange={(e) => uploadXML(e)} />
                                    </div>
                                </div>
                                <SpaceAdaptiveSmall />
                            </div>
                        </BiggerTooltip>
                    </PaneContent>
                </PageContainer>
            </>
        )
    }

    if (selectedPage === 'ABOUT') {
        return (
            <>
                <NavBar buttonName='Wstecz' sectionName='ABOUT' onButtonClick={() => { setSelectedPage('MAIN'); setUploadedFile(null) }} />
                <PageContainer>
                    <AboutPage />
                </PageContainer>
            </>
        )
    }

    if (selectedPage === 'DATMAN') {
        return (
            <>
                <NavBar buttonName='Wstecz' sectionName='DATMAN' onButtonClick={() => { setSelectedPage('MAIN'); setUploadedFile(null) }} />
                <PageContainer>
                    <AIFMCreator obj={uploadedFile} />
                </PageContainer>
            </>
        )
    }

    if (selectedPage === 'DATAIF') {
        return (
            <>
                <NavBar buttonName='Wstecz' sectionName='DATAIF' onButtonClick={() => { setSelectedPage('MAIN'); setUploadedFile(null) }} />
                <PageContainer>
                    <AIFCreator obj={uploadedFile} />
                </PageContainer>
            </>
        )
    }

    if (selectedPage === 'DATMAN_CANCEL') {
        return (
            <>
                <NavBar buttonName='Wstecz' sectionName='DATMAN_CANCEL' onButtonClick={() => { setSelectedPage('MAIN'); setUploadedFile(null) }} />
                <PageContainer>
                    <AIFMCancel obj={uploadedFile} />
                </PageContainer>
            </>
        )
    }

    if (selectedPage === 'DATAIF_CANCEL') {
        return (
            <>
                <NavBar buttonName='Wstecz' sectionName='DATAIF_CANCEL' onButtonClick={() => { setSelectedPage('MAIN'); setUploadedFile(null) }} />
                <PageContainer>
                    <AIFCancel obj={uploadedFile} />
                </PageContainer>
            </>
        )
    }

    return (
        <PageLoaderContainer>
            <Loading show message='Trwa ładowanie raportu' />
        </PageLoaderContainer>
    )
}