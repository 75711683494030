import { Button } from '../button/Button';
import styles from './MultipleElementsWrapper.module.css';

interface MultipleElementsWrapperProps {
    buttonText?: string
    buttonClick?: () => {}
    children: React.ReactNode
}

const MultipleElementsWrapper = (props: MultipleElementsWrapperProps) => {

    const { buttonText, buttonClick, children } = props;

    const createButtonSection = () => {
        if (buttonText && buttonClick) {
            return (
                <div className={styles['button-wrapper']}>
                    <Button onClick={buttonClick}>{buttonText}</Button>
                </div>
            )
        }

        return null;
    }

    return (
        <div className={styles['wrapper']}>
            {children}
            {createButtonSection()}
        </div>
    );
}

export default MultipleElementsWrapper;